<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="12" sm="12">
              <v-alert type="info">Data Pengguna</v-alert>
            </v-col>
            <v-col md="4" sm="12" class="p-5">
              <v-text-field
                v-model="form.nama"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Pengguna"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col md="4" sm="12" class="p-5">
              <v-text-field
                v-model="form.username"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Username"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col md="4" sm="12" class="p-5">
              <v-text-field
                v-model="form.password"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Password"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Users
    <Alert
      :status="isErrorGetUsers && errorCodeGetUsers !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetUsers"
    /> -->
  </v-container>
</template>
<script>
// import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UserControllers } from "../../controllers/UserControllers.js";
import Swal from "sweetalert2";

export default {
  name: "create-user",
  data: () => ({
    user: new UserControllers(),
    valid: true,
    title: "Buat Pengguna",

    isLoading: false,
    fullPage: true,

    form: {
      nama: null,
      username: "",
      password: "Sns2021"
    },

    selectScope: {
      page: 1,
      limit: 600,
    },
  }),

  components: {
    Loading,
    // Alert,
  },

  computed: {
    // users
    isErrorCreateUser() {
      return this.user.isError;
    },

    errorCauseCreateUser() {
      return this.user.errorCause;
    },

    isLoadingCreateUser() {
      return this.user.isLoading;
    },
  },

  // created() {
  //   this.getUsers();
  //   this.getBrands();
  //   this.getCategories();
  //   this.getProvinces();
  //   this.getAllDistricts();
  // },

  methods: {
    back() {
      this.$router.go(-1);
    },

    async submitProcess(
      nama,
      username,
      password
    ) {
      return this.user.createUserList(
        nama,
        username,
        password,
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.nama,
          this.form.username,
          this.form.password
        );

        if (this.isErrorCreateUser) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateUser,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "SUKSES",
            text: "Data berhasil dibuat",
          });
          // this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="12" sm="12">
              <v-alert type="info">Data Item Perawatan Precheck</v-alert>
            </v-col>
            <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="brand"
                :items="brands"
                item-text="brand"
                item-value="brand"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Brand"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="form.Harga_a"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Harga A"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="form.Harga_d"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Harga D"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="form.Dealer"
                :items="dealers"
                item-text="dealer"
                item-value="dealer"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Dealer"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="form.Harga_b"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Harga B"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="form.Harga_e"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Harga E"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-text-field
                v-model="form.keterangan"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Keterangan"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="form.Harga_c"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Harga C"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>


    <!-- Error Brands -->
    <Alert
      :status="isErrorGetBrands && errorCodeGetBrands !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetBrands"
    />
  </v-container>
</template>
<script>
import { PerawatanPostcheckControllers } from "../../controllers/PerawatanPostcheckControllers.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { DropdownControllers } from "../../controllers/DropdownControllers.js";
import Swal from "sweetalert2";

export default {
  name: "create-perewatan-precheck",
  data: () => ({
    perawatanPostcheck : new PerawatanPostcheckControllers,
    dropdown : new DropdownControllers,
    valid: true,
    title: "Tambah Item Perawatan Postcheck",

    isLoading: false,
    fullPage: true,
    brand : "",

    form: {
      dealer: "",
      keterangan: "",
      Harga_a: null,
      Harga_b: null,
      Harga_c: null,
      Harga_d: null,
      Harga_e: null,

    },

    selectScope: {
      page: 1,
      limit: 600,
    },
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    chosenBrand(){
      return this.brand;
    },

    // perawatanPostchecks
    isErrorCreatePerawatanPostcheck() {
      return this.perawatanPostcheck.isError;
    },

    errorCauseCreatePerawatanPostcheck() {
      return this.perawatanPostcheck.errorCause;
    },

    isLoadingCreatePerawatanPostcheck() {
      return this.perawatanPostcheck.isLoading;
    },

    brands(){
      return this.dropdown.brandList
    },

    errorCodeGetBrands() {
      return this.dropdown.errorCodeBrand;
    },

    isErrorGetBrands() {
      return this.dropdown.isErrorBrand;
    },

    errorCauseGetBrands() {
      return this.dropdown.errorCauseBrand;
    },

    dealers(){
      return this.dropdown.dealerList
    },

    errorCodeGetDealers() {
      return this.dropdown.errorCodeDealer;
    },

    isErrorGetDealers() {
      return this.dropdown.isErrorDealer;
    },

    errorCauseGetDealers() {
      return this.dropdown.errorCauseDealer;
    } 
  },

  created() {
  this.getBrandList()
  },

  watch:{
    chosenBrand(newBrand){
      this.getDealerList(newBrand)
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    getBrandList() {
      return this.dropdown.getBrandList();
    },

    getDealerList(brand) {
      return this.dropdown.getDealerList(brand);
    },

    
    async submitProcess(
      dealer,
      keterangan,
      Harga_a,
      Harga_b,
      Harga_c,
      Harga_d,
      Harga_e
    ) {
      return this.perawatanPostcheck.createPerawatanPostcheckList(
        dealer,
        keterangan,
        Harga_a,
        Harga_b,
        Harga_c,
        Harga_d,
        Harga_e
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.dealer,
          this.form.keterangan,
          Number(this.form.Harga_a),
          Number(this.form.Harga_b),
          Number(this.form.Harga_c),
          Number(this.form.Harga_d),
          Number(this.form.Harga_e)
        );

        if (this.isErrorCreatePerawatanPostcheck) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreatePerawatanPostcheck,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "SUKSES",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
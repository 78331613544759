import Cookies from "js-cookie";

export const setAuthentication = (token, id, user, email, role) => {
    Cookies.set('sns_access_token', token);
    if(role==99){
        Cookies.set('sns_role', 'admin.it');  
    }else if(role == 3){
        Cookies.set('sns_role', 'admin.part');  
    }
    Cookies.set('sns_user_id', id);
    Cookies.set('sns_user', user);
    Cookies.set('sns_email', email != null? email : "-");
}   

// export const setAuthentication = () => {
//     Cookies.set('sns_access_token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImYwYjcwYzQzZTkyMjNmYzM1MjU5Nzc0ZTY4ZjgwNWY3YTcwZDAzNjhiMGUxOTljZTliZjdhZmRhYzEwOGViYzU4MmRlNzA0N2Q0NTQ3Y2YzIn0.eyJhdWQiOiIxIiwianRpIjoiZjBiNzBjNDNlOTIyM2ZjMzUyNTk3NzRlNjhmODA1ZjdhNzBkMDM2OGIwZTE5OWNlOWJmN2FmZGFjMTA4ZWJjNTgyZGU3MDQ3ZDQ1NDdjZjMiLCJpYXQiOjE2NjA0NjA2NTMsIm5iZiI6MTY2MDQ2MDY1MywiZXhwIjoxNjkxOTk2NjUzLCJzdWIiOiI0Iiwic2NvcGVzIjpbXX0.DtQHfOTDxcM6acz4DGD4aSFwg7pjIhfYSMNvLKqDuizeFCi3i89rrZbn_IPD-dZ6jqpFcW7Xm9kDdzljyhj4-tXKo0ON4NZs5xQJquqOgZ8qe27CmoNUGae94WLk-TfakmxN6SxdPV8zrnHlelgcY65xyupqQZN3W_W_yLTu_XpdjNhQqplGxSVdoasdjm_FT5Dc7nzSDJ5a8OQ9d7hPf_XmZ225SVnwKw2ex1tW2yjfNBmx8IuAxmyfzzjXXpYNKeKsI9SllVEPy154mLhGlRDFULFMjD5F2a8PNuzubLN1uavHUdxyOQBxQnh4CBO9aBb9OUSa9DCCJ8lgGyZyFd1bORrP8_YkZGA91grAglOgfg1vx92L8F2I4TXAvdzQ12W6yU4rEy6OLMeY5Lr3IsFbynn7z2bWVpR8AH2nPZeOfa1zoZq-ju-gfPD_cBjqb_dCY40P-wnMFksgtAVTJvT5ltGKKYht0PF2EvkPGBur4jKP5g_T4NcDRgqNw81WpBvMoeqnb1fXvU_kZpkEv3cvSPfGPsE9vyFRaT8ccVsrGSDoSqYPuelLpx8IUcMn6WnVH9x08LFdheE7dQTE66-eFKiaGA-OviXbv2gEJfSnd25CzqQS9L4u7_h0jukpwdB5WzeigSlIfKgM6m3kefOMYftyV2ccJ_XAiFVgwMc');
//     Cookies.set('sns_expired_token', '1000000');
//     Cookies.set('sns_role', 'admin.it');
//     Cookies.set('sns_user_id', '1');
//     Cookies.set('sns_user', 'Farhan Haidar Rosadi');
//     Cookies.set('sns_email', 'fhrosadi@gmail.com');
// }   

export const getToken = () => Cookies.get('sns_access_token') || "";
export const getRole = () => Cookies.get('sns_role') || 'default';
export const getUserId = () => Cookies.get('sns_user_id') || 'default';
export const getUser = () => Cookies.get('sns_user') || 'default';
export const getEmail = () => Cookies.get('sns_email') || 'default';

export const removeAuth = () => {
    Cookies.remove('sns_access_token');
    Cookies.remove('sns_role');
    Cookies.remove('sns_user_id');
    Cookies.remove('sns_user');
    Cookies.remove('sns_email');
}
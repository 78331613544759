import moment from "moment";
export const formatDate = (date) => {
    return moment(date).format("DD MMMM YYYY, HH:mm");
};

export const formatPrice = (angka, prefix) => {
    let number_string = angka.replace(/[^,\d]/g, '').toString(),
        split = number_string.split(','),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
        const separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix == undefined ? rupiah : (rupiah ? 'Rp ' + rupiah : '');
};

export const titleCase = (string) => {
    const sentence = string?.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    const maps = sentence.map(item => item.toLowerCase() === 'dso' ? 'DSO' : item);
    return maps.join(" ");
}
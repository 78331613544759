const Login = () => {
    return `/api/user/loginit`;
}

// const Logout = () => {
//     return `/dashboard/v1/logout`;
// }

const CreateUser = () => {
    return `/api/user/registerit`
}

const UserList = () => {
    return `/api/user/listall`
}

const UpdateUser = (username) => {
    return `/api/user/update/${username}`
}

const DeleteUser = (username) => {
    return `/api/user/delete/${username}`
}

const DealerCabangList = () => {
    return `/api/listalldealercabang`
}

const CreateCabang = () => {
    return `/api/tambahdealercabang`
}

const UpdateCabang = (id) => {
    return `/api/updatedealercabang/${id}`
}

const DeleteCabang = (id) => {
    return `/api/deletedealercabang/${id}`
}

const PerawatanPostcheckList = () => {
    return `/api/listperawatanpostcheck`
}
const PergantianPostcheckList = () => {
    return `/api/listpergantianpostcheck`
}
const PerawatanPrecheckList = () => {
    return `/api/listperawatanprecheck`
}
const PergantianPrecheckList = () => {
    return `/api/listpergantianprecheck`
}

const BrandList = () => {
    return `/api/listbrandit`
}

const DealerList = (brand) => {
    return `/api/listdealerit/${brand}`
}

const CabangList = (dealer) => {
    return `/api/listcabangit/${dealer}`
}

const DropdownPerawatanPostcheckList = () => {
    return `/api/itemperawatanpostcheck`
}

const CreatePerawatanPostcheckList = () => {
    return `/api/tambahperawatanpostcheck`
}

const DeletePerawatanPostcheckList = (id) => {
    return `/api/deleteperawatanpostcheck/${id}`
}

const UpdatePerawatanPostcheckList = (id) => {
    return `/api/updateperawatanpostcheck/${id}`
}

const DropdownPerawatanPrecheckList = () => {
    return `/api/itemperawatanprecheck`
}

const CreatePerawatanPrecheckList = () => {
    return `/api/tambahperawatanprecheck`
}

const DeletePerawatanPrecheckList = (id) => {
    return `/api/deleteperawatanprecheck/${id}`
}

const UpdatePerawatanPrecheckList = (id) => {
    return `/api/updateperawatanprecheck/${id}`
}

const DropdownPergantianPostcheckList = () => {
    return `/api/itempergantianpostcheck`
}

const CreatePergantianPostcheckList = () => {
    return `/api/tambahpergantianpostcheck`
}

const DeletePergantianPostcheckList = (id) => {
    return `/api/deletepergantianpostcheck/${id}`
}

const UpdatePergantianPostcheckList = (id) => {
    return `/api/updatepergantianpostcheck/${id}`
}

const DropdownPergantianPrecheckList = () => {
    return `/api/itempergantianprecheck`
}

const CreatePergantianPrecheckList = () => {
    return `/api/tambahpergantianprecheck`
}

const DeletePergantianPrecheckList = (id) => {
    return `/api/deletepergantianprecheck/${id}`
}

const UpdatePergantianPrecheckList = (id) => {
    return `/api/updatepergantianprecheck/${id}`
}



const SAList = () => {
    return `/api/listallsa`
}
const CreateSA = () => {
    return `/api/tambahsa`
}
const UpdateSA = (id) => {
    return `/api/updatesa/${id}`
}
const DeleteSA = (id) => {
    return `/deletesa/${id}`
}
const CarList = () => {
    return `/api/listmodel`
}
const CreateCar = () => {
    return `/api/tambahmodel`
}
const UpdateCar = (id) => {
    return `/api/updatemodel/${id}`
}
const DeleteCar = (id) => {
    return `/api/deletemodel/${id}`
}
const OpnameList = () => {
    return `/opname`
}




module.exports = {
    Login,
    // Logout,
    UserList,
    CreateUser,
    UpdateUser,
    DeleteUser,
    DealerCabangList,
    CreateCabang,
    UpdateCabang,
    DeleteCabang,

    PerawatanPostcheckList,
    CreatePerawatanPostcheckList,
    UpdatePerawatanPostcheckList,
    DeletePerawatanPostcheckList,

    PerawatanPrecheckList,
    CreatePerawatanPrecheckList,
    DeletePerawatanPrecheckList,
    UpdatePerawatanPrecheckList,

    PergantianPostcheckList,
    CreatePergantianPostcheckList,
    UpdatePergantianPostcheckList,
    DeletePergantianPostcheckList,

    PergantianPrecheckList,
    CreatePergantianPrecheckList,
    UpdatePergantianPrecheckList,
    DeletePergantianPrecheckList,

    DropdownPerawatanPostcheckList,
    DropdownPerawatanPrecheckList,
    DropdownPergantianPostcheckList,
    DropdownPergantianPrecheckList,
    BrandList,
    DealerList,
    CabangList,
    SAList,
    CreateSA,
    UpdateSA,
    DeleteSA,
    CarList,
    CreateCar,
    UpdateCar,
    DeleteCar,
    OpnameList
}

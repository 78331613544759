import {
    PergantianPostcheckListRequest,
    CreatePergantianPostcheckRequest,
    DeletePergantianPostcheckRequest,
    UpdatePergantianPostcheckRequest,
    // PergantianPostcheckDetailRequest
} from "../request/PergantianPostcheckRequest.js";

export class PergantianPostcheckControllers {
    pergantianpostcheckList = new Array();
    pergantianpostcheckDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getPergantianPostcheckList() {
        this.setLoading(true);
        const resp = PergantianPostcheckListRequest()
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.status);
                this.setPergantianPostcheckList(response.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.statusText);
                this.setErrorcode(err.status);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    // getPergantianPostcheckDetailEdit(id) {
    //     this.setLoading(true);
    //     const resp = PergantianPostcheckDetailRequest(id)
    //         .then((response) => {
    //             this.setError(false);
    //             this.setErrorcode(response.data.code);
    //             this.setPergantianPostcheckDetail(response.data.data);
    //             return true;
    //         }).catch(err => {
    //             this.setError(true);
    //             this.setErrorCause(err.response.data.message);
    //             this.setErrorcode(err.response.data.code);
    //             return false;
    //         }).finally(() => {
    //             this.setLoading(false);
    //         });

    //     return resp;
    // }

    // downloadPergantianPostcheckList(role, page, limit, keyword, dsoId) {
    //     this.setLoadingDownload(true);
    //     const resp = FilterPergantianPostcheckAMRequest(role, page, limit, keyword, dsoId)
    //         .then((response) => {
    //             this.setErrorDownload(false);
    //             this.setErrorcodeDownload(response.data.code);
    //             this.setPergantianPostcheckListDownload(response.data.data);
    //         }).catch(err => {
    //             this.setPergantianPostcheckListDownload([])
    //             this.setErrorDownload(true);
    //             this.setErrorCauseDownload(err.response.data.message);
    //             this.setErrorcodeDownload(err.response.data.code);
    //         }).finally(() => {
    //             this.setLoadingDownload(false);
    //         });

    //     return resp;
    // }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setPergantianPostcheckListDownload(data) {
        this.pergantianpostcheckDownload = data;
    }

    setPergantianPostcheckDetail(data) {
        this.pergantianpostcheckDetail = data;
    }

    setPergantianPostcheckList(data) {
        this.pergantianpostcheckList = data;
    }

    createPergantianPostcheckList(
        dealer,
        keterangan,
        Harga_a,
        Harga_b,
        Harga_c,
        Harga_d,
        Harga_e
    ) {
        this.setLoading(true);
        const resp = CreatePergantianPostcheckRequest(
            dealer,
            keterangan,
            Harga_a,
            Harga_b,
            Harga_c,
            Harga_d,
            Harga_e
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                let text = 'An error occurred while creating pergantianpostcheck'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    getPergantianPostcheckDetail(id) {
        this.pergantianpostcheckDetail = this.pergantianpostcheckList.find(item => {
            return item.id === id
        });
    }

    deletePergantianPostcheck(id) {
        this.setLoading(true);
        const resp = DeletePergantianPostcheckRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                let text = 'An error occurred while deleting pergantian postcheck'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updatePergantianPostcheckList(
        id,
        dealer,
        keterangan,
        Harga_a,
        Harga_b,
        Harga_c,
        Harga_d,
        Harga_e
    ) {
        this.setLoading(true);
        const resp = UpdatePergantianPostcheckRequest(
            id,
            dealer,
            keterangan,
            Harga_a,
            Harga_b,
            Harga_c,
            Harga_d,
            Harga_e
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                let text = 'An error occurred while updating pergantian postcheck'
                if(err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  getToken,
  removeAuth,
  getRole
} from "../external/functions/Cookies.js";
import {
  defineRole
} from "../external/functions/Roles"
import Auth from "../views/auth"
import NotFound from "../views/notfound/index.vue"
import UserList from "../views/user/user-list.vue"
import CreateUser from "../views/user/create-user.vue"
import UpdateUser from "../views/user/edit-user.vue"
import SAList from "../views/sa/sa-list.vue"
import CreateSA from "../views/sa/create-sa.vue"
import UpdateSA from "../views/sa/edit-sa.vue"
import CabangList from "../views/dealer-cabang/dealer-cabang-list.vue"
import CreateCabang from "../views/dealer-cabang/create-dealer-cabang.vue"
import UpdateCabang from "../views/dealer-cabang/edit-dealer-cabang.vue"
import CarList from "../views/car-type/car-type-list.vue"
import CreateCar from "../views/car-type/create-car-type.vue"
import UpdateCar from "../views/car-type/edit-car-type.vue"

import ItemPerawatanPostcheckList from "../views/item-perawatan-postcheck/item-list.vue"
import CreateItemPerawatanPostcheck from "../views/item-perawatan-postcheck/create-item.vue"
import UpdateItemPerawatanPostcheck from "../views/item-perawatan-postcheck/edit-item.vue"
import ItemPerawatanPrecheckList from "../views/item-perawatan-precheck/item-list.vue"
import CreateItemPerawatanPrecheck from "../views/item-perawatan-precheck/create-item.vue"
import UpdateItemPerawatanPrecheck from "../views/item-perawatan-precheck/edit-item.vue"

import ItemPergantianPostcheckList from "../views/item-pergantian-postcheck/item-list.vue"
import CreateItemPergantianPostcheck from "../views/item-pergantian-postcheck/create-item.vue"
import UpdateItemPergantianPostcheck from "../views/item-pergantian-postcheck/edit-item.vue"
import ItemPergantianPrecheckList from "../views/item-pergantian-precheck/item-list.vue"
import CreateItemPergantianPrecheck from "../views/item-pergantian-precheck/create-item.vue"
import UpdateItemPergantianPrecheck from "../views/item-pergantian-precheck/edit-item.vue"

import OpnameList from "../views/stock-opname/opname-list.vue"

// import WarehouseList from "../views/warehouse/warehouse-list.vue"
// import CreateWarehouse from "../views/warehouse/create-warehouse.vue"
// import UpdateWarehouse from "../views/warehouse/edit-warehouse.vue"
// import WarehouseTransitList from "../views/warehouse-transit/warehouse-list.vue"
// import CreateWarehouseTransit from "../views/warehouse-transit/create-warehouse.vue"
// import UpdateWarehouseTransit from "../views/warehouse-transit/edit-warehouse.vue"
// import WarehouseBranchList from "../views/warehouse-branch/warehouse-list.vue"
// import CreateWarehouseBranch from "../views/warehouse-branch/create-warehouse.vue"
// import UpdateWarehouseBranch from "../views/warehouse-branch/edit-warehouse.vue"

Vue.use(VueRouter)

const role = getRole();

const routes = [
  {
    path: '/user-list',
    name: 'User List',
    component: UserList,
    meta: {
      role: defineRole(role, 'user')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'user');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/create-user',
    name: 'Create User',
    component: CreateUser,
    meta: {
      role: defineRole(role, 'user')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'user');
      if (!access.create) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/update-user/:username',
    name: 'Update User',
    component: UpdateUser,
    meta: {
      role: defineRole(role, 'user')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'user');
      if (!access.update) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/car-type-list',
    name: 'Car Type List',
    component: CarList,
    meta: {
      role: defineRole(role, 'car-type')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'car-type');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/create-car-type',
    name: 'Create Car Type',
    component: CreateCar,
    meta: {
      role: defineRole(role, 'car-type')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'car-type');
      if (!access.create) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/update-car-type/:id',
    name: 'Update Car Type',
    component: UpdateCar,
    meta: {
      role: defineRole(role, 'car-type')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'car-type');
      if (!access.update) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/dealer-cabang-list',
    name: 'Dealer Cabang List',
    component: CabangList,
    meta: {
      role: defineRole(role, 'cabang')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'cabang');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/create-dealer-cabang',
    name: 'Create Dealer Cabang',
    component: CreateCabang,
    meta: {
      role: defineRole(role, 'cabang')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'cabang');
      if (!access.create) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/update-dealer-cabang/:id',
    name: 'Update Dealer Cabang',
    component: UpdateCabang,
    meta: {
      role: defineRole(role, 'cabang')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'cabang');
      if (!access.update) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/sa-list',
    name: 'SA List',
    component: SAList,
    meta: {
      role: defineRole(role, 'sa')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'sa');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/create-sa',
    name: 'Create SA',
    component: CreateSA,
    meta: {
      role: defineRole(role, 'sa')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'sa');
      if (!access.create) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/update-sa/:id',
    name: 'Update SA',
    component: UpdateSA,
    meta: {
      role: defineRole(role, 'sa')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'sa');
      if (!access.update) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/perawatan-postcheck-list',
    name: 'Perawatan Postcheck List',
    component: ItemPerawatanPostcheckList,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/create-perawatan-postcheck',
    name: 'Create Perawatan Postcheck',
    component: CreateItemPerawatanPostcheck,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.create) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/update-perawatan-postcheck/:id',
    name: 'Update Perawatan Postcheck',
    component: UpdateItemPerawatanPostcheck,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.update) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/perawatan-precheck-list',
    name: 'Perawatan Precheck List',
    component: ItemPerawatanPrecheckList,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/create-perawatan-precheck',
    name: 'Create Perawatan Precheck',
    component: CreateItemPerawatanPrecheck,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.create) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/update-perawatan-precheck/:id',
    name: 'Update Perawatan Precheck',
    component: UpdateItemPerawatanPrecheck,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.update) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/pergantian-postcheck-list',
    name: 'Pergantian Postcheck List',
    component: ItemPergantianPostcheckList,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/create-pergantian-postcheck',
    name: 'Create Pergantian Postcheck',
    component: CreateItemPergantianPostcheck,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.create) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/update-pergantian-postcheck/:id',
    name: 'Update Pergantian Postcheck',
    component: UpdateItemPergantianPostcheck,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.update) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/pergantian-precheck-list',
    name: 'Pergantian Precheck List',
    component: ItemPergantianPrecheckList,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/create-pergantian-precheck',
    name: 'Create Pergantian Precheck',
    component: CreateItemPergantianPrecheck,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.create) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/update-pergantian-precheck/:id',
    name: 'Update Pergantian Precheck',
    component: UpdateItemPergantianPrecheck,
    meta: {
      role: defineRole(role, 'item-care')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'item-care');
      if (!access.update) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/opname-list',
    name: 'Opname List',
    component: OpnameList,
    meta: {
      role: defineRole(role, 'opname')
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, 'opname');
      if (!access.read) {
        next({
          name: 'Not Found'
        });
      }

      next();
    }
  },
  {
    path: '/',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/not-found',
    name: 'Not Found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  function checkAuth() {
    const token = getToken();
    return (token)
  }

  const isAuth = checkAuth();

  if (to.name !== "Auth" && !isAuth) {
    removeAuth();
    next({
      name: 'Auth'
    });
    return;
  }

  if (to.name === "Auth" && isAuth) {
    next("user-list");
    return;
  }

  if (!routes.some(route=>route.name === to.name)){
    next("not-found");
    return;
  }

  next()

});

export default router
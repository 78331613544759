import {
    SAListRequest,
    CreateSARequest,
    DeleteSARequest,
    UpdateSARequest,
    // SADetailRequest
} from "../request/SARequest.js";

export class SAControllers {
    saList = new Array();
    saDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getSAList() {
        this.setLoading(true);
        const resp = SAListRequest()
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.status);
                this.setSAList(response.data);
                return true;
            }).catch(err => {
                console.log(err)
                this.setError(true);
                this.setErrorCause(err.statusText);
                this.setErrorcode(err.status);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    // getSADetailEdit(id) {
    //     this.setLoading(true);
    //     const resp = SADetailRequest(id)
    //         .then((response) => {
    //             this.setError(false);
    //             this.setErrorcode(response.data.code);
    //             this.setSADetail(response.data.data);
    //             return true;
    //         }).catch(err => {
    //             this.setError(true);
    //             this.setErrorCause(err.response.data.message);
    //             this.setErrorcode(err.response.data.code);
    //             return false;
    //         }).finally(() => {
    //             this.setLoading(false);
    //         });

    //     return resp;
    // }

    // downloadSAList(role, page, limit, keyword, dsoId) {
    //     this.setLoadingDownload(true);
    //     const resp = FilterSAAMRequest(role, page, limit, keyword, dsoId)
    //         .then((response) => {
    //             this.setErrorDownload(false);
    //             this.setErrorcodeDownload(response.data.code);
    //             this.setSAListDownload(response.data.data);
    //         }).catch(err => {
    //             this.setSAListDownload([])
    //             this.setErrorDownload(true);
    //             this.setErrorCauseDownload(err.response.data.message);
    //             this.setErrorcodeDownload(err.response.data.code);
    //         }).finally(() => {
    //             this.setLoadingDownload(false);
    //         });

    //     return resp;
    // }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setSAListDownload(data) {
        this.saDownload = data;
    }

    setSADetail(data) {
        this.saDetail = data;
    }

    setSAList(data) {
        this.saList = data;
    }

    createSAList(
        dealer,
        cabang,
        nama_sa
    ) {
        this.setLoading(true);
        const resp = CreateSARequest(
                dealer,
                cabang,
                nama_sa
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                console.log(err)
                let text = 'An error occurred while creating SA'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
            this.setError(true);
            this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    getSADetail(id) {
        this.saDetail = this.saList.find(item => {
            return item.id === id
        });
    }

    deleteSA(id) {
        this.setLoading(true);
        const resp = DeleteSARequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                let text = 'An error occurred while deleting SA'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateSAList(
        id,
        dealer,
        cabang,
        nama_sa
    ) {
        this.setLoading(true);
        const resp = UpdateSARequest(
                id,
                dealer,
                cabang,
                nama_sa
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                let text = 'An error occurred while Updating SA'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}
import axios from "axios";
import FormData from 'form-data';
// import qs from 'qs'
import { DealerCabangList, CreateCabang, DeleteCabang, UpdateCabang } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
// import { getToken } from "../external/functions/Cookies.js";
// const headers = {
//     'Authorization': `Bearer ${getToken()}`
// };
export const DealerCabangListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${DealerCabangList()}`,
        // headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateDealerCabangRequest = (
    brand,
    dealer,
    cabang,
    alamat,
    waktu,
    kode,
    kodeInt,
    foto
) => {
    let formData = new FormData()
    formData.append('brand', brand)
    formData.append('dealer', dealer)
    formData.append('cabang', cabang)
    formData.append('foto', foto)
    formData.append('alamat', alamat)
    formData.append('masuk', waktu)
    formData.append('kodewilayah', kode)
    formData.append('kodewilayah_internal', kodeInt)
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateCabang()}`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteDealerCabangRequest = (id) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${DeleteCabang(id)}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateDealerCabangRequest = (
    id,
    brand,
    dealer,
    cabang,
    alamat,
    waktu,
    kode,
    kodeInt,
    foto
) => {
    let formData = new FormData()
    formData.append('brand', brand)
    formData.append('dealer', dealer)
    formData.append('cabang', cabang)
    formData.append('foto', foto)
    formData.append('alamat', alamat)
    formData.append('masuk', waktu)
    formData.append('kodewilayah', kode)
    formData.append('kodewilayah_internal', kodeInt)
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateCabang(id)}`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
        return response;
    });

    return resp;
}

// export const BrandDetailRequest = (id) => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${BrandDetail(id)}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }

// export const ProductListRequest = () => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${ProductList()}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }




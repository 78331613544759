import axios from "axios";
import qs from 'qs'
// import FormData from 'form-data';
import { SAList, CreateSA, DeleteSA, UpdateSA} from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`,
    'Content-Type': 'application/x-www-form-urlencoded'
};
export const SAListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${SAList()}`,
    }).then((response) => {
        return response;
    });

    return resp;
}


export const CreateSARequest = (
    dealer,
    cabang,
    nama_sa
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateSA()}`,
        data: qs.stringify({
            dealer: dealer,
            cabang: cabang,
            nama_sa: nama_sa
        }),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteSARequest = (id) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${DeleteSA(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateSARequest = (
    id,
    dealer,
    cabang,
    nama_sa
) => {
    let formData = new FormData()
    formData.append('dealer', dealer)
    formData.append('cabang', cabang)
    formData.append('nama_sa', nama_sa)
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateSA(id)}`,
        data: formData,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

// export const FilterSAAMRequest = (role, page, limit, keyword, dsoId) => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${FilterSAAM(role, page, limit, keyword, dsoId)}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }

// export const SADetailRequest = (id) => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${SADetail(id)}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }



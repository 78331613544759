<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="12" sm="12">
              <v-alert type="info">Data PergantianPrecheck</v-alert>
            </v-col>
            <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="brand"
                :items="brands"
                item-text="brand"
                item-value="brand"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Brand"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="dealer"
                :items="dealers"
                item-text="dealer"
                item-value="dealer"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Dealer"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-text-field
                v-model="keterangan"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Keterangan"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Brands -->
    <Alert
      :status="isErrorBrand && errorCodeBrand !== 404"
      icon="error"
      title="KEPergantianPrecheckLAHAN"
      :msg="errorCauseBrand"
    />
  </v-container>
</template>
<script>
import { PergantianPrecheckControllers } from "../../controllers/PergantianPrecheckControllers.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { DropdownControllers } from "../../controllers/DropdownControllers.js";
import Swal from "sweetalert2";

export default {
  name: "update-perawatan-precheck",
  data: () => ({
    PergantianPrecheck : new PergantianPrecheckControllers,
    dropdown : new DropdownControllers,
    valid: true,
    title: "Ubah Pergantian Precheck",

    isLoading: false,
    fullPage: true,
    brand : "",
    dealer: "",
    keterangan: "",    

    selectScope: {
      page: 1,
      limit: 600,
    },
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
 // dealercabangs
    isErrorUpdatePergantianPrecheck() {
      return this.PergantianPrecheck.isError;
    },

    errorCodeUpdatePergantianPrecheck(){
      return this.PergantianPrecheck.errorCode;
    },

    errorCauseUpdatePergantianPrecheck() {
      return this.PergantianPrecheck.errorCause;
    },

    isLoadingUpdatePergantianPrecheck() {
      return this.PergantianPrecheck.isLoading;
    },
    
    PergantianPrecheckId(){
      return this.$route.params.id
    },

    // brands
    brands() {
      return this.dropdown.brandList;
    },

    errorCodeGetBrands() {
      return this.dropdown.errorCodeBrand;
    },

    isErrorGetBrands() {
      return this.dropdown.isErrorBrand;
    },

    errorCauseGetBrands() {
      return this.dropdown.errorCauseBrand;
    },

    // dealers
    dealers() {
      return this.dropdown.dealerList
    },

    errorCodeGetDealers() {
      return this.dropdown.errorCodeDealer;
    },

    isErrorGetDealers() {
      return this.dropdown.isErrorDealer;
    },

    errorCauseGetDealers() {
      return this.dropdown.errorCauseDealer;
    },

    // cabangs
    cabangs() {
      return this.dropdown.cabangList
    },

    errorCodeGetCabangs() {
      return this.dropdown.errorCodeCabang;
    },

    isErrorGetCabangs() {
      return this.dropdown.isErrorCabang;
    },

    errorCauseGetCabangs() {
      return this.dropdown.errorCauseCabang;
    },
  },

  created() {
  this.getBrandList()
  this.dealer = this.$route.params.data.dealer
  this.keterangan = this.$route.params.data.keterangan
  },

  watch:{
    brand(newBrand){
      this.getDealerList(newBrand)
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    getBrandList() {
      return this.dropdown.getBrandList();
    },

    getDealerList(brand) {
      return this.dropdown.getDealerList(brand);
    },

    async submitProcess(
      dealer,
      keterangan
    ) {
      return this.PergantianPrecheck.updatePergantianPrecheckList(
        this.$route.params.id,
        dealer,
        keterangan
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.dealer,
          this.keterangan
        );

        if (this.isErrorUpdatePergantianPrecheck) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdatePergantianPrecheck,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diupdate",
          });
          this.back()
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorUpdatePergantianPrecheck = false;
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
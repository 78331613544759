import {
    UserListRequest,
    CreateUserRequest,
    DeleteUserRequest,
    UpdateUserRequest,
    // UserDetailRequest
} from "../request/UserRequest.js";

export class UserControllers {
    userList = new Array();
    userDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getUserList() {
        this.setLoading(true);
        const resp = UserListRequest()
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.status);
                this.setUserList(response.data);
                return true;
            }).catch(err => {
                console.log(err.response)
                this.setError(true);
                this.setErrorCause(err.statusText);
                this.setErrorcode(err.status);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    // getUserDetailEdit(id) {
    //     this.setLoading(true);
    //     const resp = UserDetailRequest(id)
    //         .then((response) => {
    //             this.setError(false);
    //             this.setErrorcode(response.data.code);
    //             this.setUserDetail(response.data.data);
    //             return true;
    //         }).catch(err => {
    //             this.setError(true);
    //             this.setErrorCause(err.response.data.message);
    //             this.setErrorcode(err.response.data.code);
    //             return false;
    //         }).finally(() => {
    //             this.setLoading(false);
    //         });

    //     return resp;
    // }

    // downloadUserList(role, page, limit, keyword, dsoId) {
    //     this.setLoadingDownload(true);
    //     const resp = FilterUserAMRequest(role, page, limit, keyword, dsoId)
    //         .then((response) => {
    //             this.setErrorDownload(false);
    //             this.setErrorcodeDownload(response.data.code);
    //             this.setUserListDownload(response.data.data);
    //         }).catch(err => {
    //             this.setUserListDownload([])
    //             this.setErrorDownload(true);
    //             this.setErrorCauseDownload(err.response.data.message);
    //             this.setErrorcodeDownload(err.response.data.code);
    //         }).finally(() => {
    //             this.setLoadingDownload(false);
    //         });

    //     return resp;
    // }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setUserListDownload(data) {
        this.userDownload = data;
    }

    setUserDetail(data) {
        this.userDetail = data;
    }

    setUserList(data) {
        this.userList = data;
    }

    createUserList(
        nama,
        username,
        password
    ) {
        this.setLoading(true);
        const resp = CreateUserRequest(
                nama,
                username,
                password
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                let text = 'An error occurred while creating user'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    getUserDetail(id) {
        this.userDetail = this.userList.find(item => {
            return item.id === id
        });
    }

    deleteUser(username) {
        this.setLoading(true);
        const resp = DeleteUserRequest(username)
            .then((response) => {
                console.log(response)
                // this.setErrorDelete(false);
                // this.setMsgDelete(response.data.message);
            }).catch(err => {
                let text = 'An error occurred while deleting user'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateUserList(
        username,
        nama,
        nik,
        kk,
        tempat_lahir,
        tanggal_lahir,
        jk,
        status,
        hobi,
        pendidikan,
        kontak,
        nip,
        dealer,
        cabang,
        soh,
        kursus,
        facebook,
        instagram,
        twitter,
        youtube,
        foto_ktp,
        foto_kk,
        foto_ijazah
    ) {
        this.setLoading(true);
        const resp = UpdateUserRequest(
            username,
            nama,
            nik,
            kk,
            tempat_lahir,
            tanggal_lahir,
            jk,
            status,
            hobi,
            pendidikan,
            kontak,
            nip,
            dealer,
            cabang,
            soh,
            kursus, 
            facebook,
            instagram,
            twitter,
            youtube,
            foto_ktp,
            foto_kk,
            foto_ijazah
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                let text = 'An error occurred while updating user'
                if(err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}
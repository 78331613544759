import axios from "axios";
import qs from 'qs'
import { PergantianPrecheckList, CreatePergantianPrecheckList, DeletePergantianPrecheckList, UpdatePergantianPrecheckList} from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const PergantianPrecheckListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${PergantianPrecheckList()}`,
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreatePergantianPrecheckRequest = (
    dealer,
    keterangan
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreatePergantianPrecheckList()}`,
        data: qs.stringify({
            dealer: dealer,
            keterangan: keterangan
        }),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeletePergantianPrecheckRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeletePergantianPrecheckList(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdatePergantianPrecheckRequest = (
    id,
    dealer,
    keterangan
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdatePergantianPrecheckList(id)}`,
        data: qs.stringify({
            dealer: dealer,
            keterangan: keterangan
        }),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then((response) => {
        return response;
    });

    return resp;
}

// export const FilterPergantianPrecheckAMRequest = (role, page, limit, keyword, dsoId) => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${FilterPergantianPrecheckAM(role, page, limit, keyword, dsoId)}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }

// export const PergantianPrecheckDetailRequest = (id) => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${PergantianPrecheckDetail(id)}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }



import axios from "axios";
import qs from 'qs'
import { PerawatanPostcheckList, DeletePerawatanPostcheckList, UpdatePerawatanPostcheckList, CreatePerawatanPostcheckList} from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const PerawatanPostcheckListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${PerawatanPostcheckList()}`,
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreatePerawatanPostcheckRequest = (
    dealer,
    keterangan,
    Harga_a,
    Harga_b,
    Harga_c,
    Harga_d,
    Harga_e
) => {
    headers['Content-Type'] = 'application/x-www-form-urlencoded'
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreatePerawatanPostcheckList()}`,
        data: qs.stringify({
            dealer: dealer,
            keterangan: keterangan,
            Harga_a: Harga_a,
            Harga_b: Harga_b,
            Harga_c: Harga_c,
            Harga_d: Harga_d,
            Harga_e: Harga_e
        }),
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeletePerawatanPostcheckRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeletePerawatanPostcheckList(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdatePerawatanPostcheckRequest = (
    id,
    dealer,
    keterangan,
    Harga_a,
    Harga_b,
    Harga_c,
    Harga_d,
    Harga_e
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdatePerawatanPostcheckList(id)}`,
        data: qs.stringify({
            dealer: dealer,
            keterangan: keterangan,
            Harga_a: Harga_a,
            Harga_b: Harga_b,
            Harga_c: Harga_c,
            Harga_d: Harga_d,
            Harga_e: Harga_e
        }),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then((response) => {
        return response;
    });

    return resp;
}

// export const FilterPerawatanPostcheckAMRequest = (role, page, limit, keyword, dsoId) => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${FilterPerawatanPostcheckAM(role, page, limit, keyword, dsoId)}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }

// export const PerawatanPostcheckDetailRequest = (id) => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${PerawatanPostcheckDetail(id)}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }



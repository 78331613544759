<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="12" sm="12">
              <v-alert type="info">Data SA</v-alert>
            </v-col>
            <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="brand"
                :items="brands"
                item-text="brand"
                item-value="brand"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Brand"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="form.nama_sa"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama SA"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="form.dealer"
                :items="dealers"
                item-text="dealer"
                item-value="dealer"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Dealer"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="form.cabang"
                :items="cabangs"
                item-text="cabang"
                item-value="cabang"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Cabang"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>


    <!-- Error Brands -->
    <Alert
      :status="isErrorGetBrands && errorCodeGetBrands !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetBrands"
    />
  </v-container>
</template>
<script>
import { SAControllers } from "../../controllers/SAControllers.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { DropdownControllers } from "../../controllers/DropdownControllers.js";
import Swal from "sweetalert2";

export default {
  name: "create-sa",
  data: () => ({
    SA : new SAControllers,
    dropdown : new DropdownControllers,
    valid: true,
    title: "Tambah SA",

    isLoading: false,
    fullPage: true,
    brand : "",

    form: {
      dealer: "",
      cabang: "",
      nama_sa: ""
    },

    selectScope: {
      page: 1,
      limit: 600,
    },
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    chosenBrand(){
      return this.brand;
    },
    chosenDealer(){
      return this.form.dealer
    },
    // SAs
    isErrorCreateSA() {
      return this.SA.isError;
    },

    errorCauseCreateSA() {
      return this.SA.errorCause;
    },

    isLoadingCreateSA() {
      return this.SA.isLoading;
    },

    brands(){
      return this.dropdown.brandList
    },

    errorCodeGetBrands() {
      return this.dropdown.errorCodeBrand;
    },

    isErrorGetBrands() {
      return this.dropdown.isErrorBrand;
    },

    errorCauseGetBrands() {
      return this.dropdown.errorCauseBrand;
    },

    dealers(){
      return this.dropdown.dealerList
    },

    errorCodeGetDealers() {
      return this.dropdown.errorCodeDealer;
    },

    isErrorGetDealers() {
      return this.dropdown.isErrorDealer;
    },

    errorCauseGetDealers() {
      return this.dropdown.errorCauseDealer;
    },

    cabangs(){
      return this.dropdown.cabangList
    },

    errorCodeGetCabangs() {
      return this.dropdown.errorCodeCabang;
    },

    isErrorGetCabangs() {
      return this.dropdown.isErrorCabang;
    },

    errorCauseGetCabangs() {
      return this.dropdown.errorCauseCabang;
    },

  
  },

  created() {
  this.getBrandList()
  },

  watch:{
    chosenBrand(newBrand){
      this.getDealerList(newBrand)
    },

    chosenDealer(newDealer){
      this.getCabangList(newDealer)
    },
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    getBrandList() {
      return this.dropdown.getBrandList();
    },

    getDealerList(brand) {
      return this.dropdown.getDealerList(brand);
    },

    getCabangList(dealer) {
      return this.dropdown.getCabangList(dealer);
    },

    
    async submitProcess(
      dealer,
      cabang,
      nama_sa
    ) {
      return this.SA.createSAList(
        dealer,
        cabang,
        nama_sa
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.dealer,
          this.form.cabang,
          this.form.nama_sa,
        );

        if (this.isErrorCreateSA) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateSA,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "SUKSES",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
import Vue from 'vue'
import Vuex from 'vuex'
// import { titleCase } from "../external/functions/Utilization.js";


Vue.use(Vuex)

export const store = new Vuex.Store({
  
});

import {
    PerawatanPostcheckListRequest,
    CreatePerawatanPostcheckRequest,
    DeletePerawatanPostcheckRequest,
    UpdatePerawatanPostcheckRequest,
    // PerawatanPostcheckDetailRequest
} from "../request/PerawatanPostcheckRequest.js";

export class PerawatanPostcheckControllers {
    perawatanpostcheckList = new Array();
    perawatanpostcheckDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getPerawatanPostcheckList() {
        this.setLoading(true);
        const resp = PerawatanPostcheckListRequest()
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.status);
                this.setPerawatanPostcheckList(response.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.statusText);
                this.setErrorcode(err.status);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    // getPerawatanPostcheckDetailEdit(id) {
    //     this.setLoading(true);
    //     const resp = PerawatanPostcheckDetailRequest(id)
    //         .then((response) => {
    //             this.setError(false);
    //             this.setErrorcode(response.data.code);
    //             this.setPerawatanPostcheckDetail(response.data.data);
    //             return true;
    //         }).catch(err => {
    //             this.setError(true);
    //             this.setErrorCause(err.response.data.message);
    //             this.setErrorcode(err.response.data.code);
    //             return false;
    //         }).finally(() => {
    //             this.setLoading(false);
    //         });

    //     return resp;
    // }

    // downloadPerawatanPostcheckList(role, page, limit, keyword, dsoId) {
    //     this.setLoadingDownload(true);
    //     const resp = FilterPerawatanPostcheckAMRequest(role, page, limit, keyword, dsoId)
    //         .then((response) => {
    //             this.setErrorDownload(false);
    //             this.setErrorcodeDownload(response.data.code);
    //             this.setPerawatanPostcheckListDownload(response.data.data);
    //         }).catch(err => {
    //             this.setPerawatanPostcheckListDownload([])
    //             this.setErrorDownload(true);
    //             this.setErrorCauseDownload(err.response.data.message);
    //             this.setErrorcodeDownload(err.response.data.code);
    //         }).finally(() => {
    //             this.setLoadingDownload(false);
    //         });

    //     return resp;
    // }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setPerawatanPostcheckListDownload(data) {
        this.perawatanpostcheckDownload = data;
    }

    setPerawatanPostcheckDetail(data) {
        this.perawatanpostcheckDetail = data;
    }

    setPerawatanPostcheckList(data) {
        this.perawatanpostcheckList = data;
    }

    createPerawatanPostcheckList(
        dealer,
        keterangan,
        Harga_a,
        Harga_b,
        Harga_c,
        Harga_d,
        Harga_e
    ) {
        this.setLoading(true);
        const resp = CreatePerawatanPostcheckRequest(
            dealer,
            keterangan,
            Harga_a,
            Harga_b,
            Harga_c,
            Harga_d,
            Harga_e
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                let text = 'An error occurred while creating perawatanpostcheck'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    getPerawatanPostcheckDetail(id) {
        this.perawatanpostcheckDetail = this.perawatanpostcheckList.find(item => {
            return item.id === id
        });
    }

    deletePerawatanPostcheck(id) {
        this.setLoading(true);
        const resp = DeletePerawatanPostcheckRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                let text = 'An error occurred while deleting perawatan postcheck'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updatePerawatanPostcheckList(
        id,
        dealer,
        keterangan,
        Harga_a,
        Harga_b,
        Harga_c,
        Harga_d,
        Harga_e
    ) {
        this.setLoading(true);
        const resp = UpdatePerawatanPostcheckRequest(
            id,
            dealer,
            keterangan,
            Harga_a,
            Harga_b,
            Harga_c,
            Harga_d,
            Harga_e
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                let text = 'An error occurred while updating perawatan postcheck'
                if(err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}
import axios from "axios";
import qs from 'qs'
import FormData from 'form-data';
import { UserList, CreateUser, DeleteUser, UpdateUser } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const UserListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${UserList()}`,
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateUserRequest = (
    nama,
    username,
    password
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateUser()}`,
        data: qs.stringify({
            nama: nama,
            username: username,
            password: password,
            re_password: password
        }),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteUserRequest = (username) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${DeleteUser(username)}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateUserRequest = (
    username,
    nama,
    nik,
    kk,
    tempat_lahir,
    tanggal_lahir,
    jk,
    status,
    hobi,
    pendidikan,
    kontak,
    nip,
    dealer,
    cabang,
    soh,
    kursus,
    facebook,
    instagram,
    twitter,
    youtube,
    foto_ktp,
    foto_kk,
    foto_ijazah
) => {
    headers['Content-Type'] = 'multipart/form-data'
    let formData = new FormData()
    formData.append('nama', nama)
    formData.append('nik', nik)
    formData.append('kk', kk)
    formData.append('tempat_lahir', tempat_lahir)
    formData.append('tanggal_lahir', tanggal_lahir)
    formData.append('jk', jk)
    formData.append('status', status)
    formData.append('hobi', hobi)
    formData.append('pendidikan', pendidikan)
    formData.append('kontak', kontak)
    formData.append('nip', nip)
    formData.append('dealer', dealer)
    formData.append('cabang', cabang)
    formData.append('soh', soh)
    formData.append('kursus', kursus)
    formData.append('facebook', facebook)
    formData.append('instagram', instagram)
    formData.append('twitter', twitter)
    formData.append('youtube', youtube)
    formData.append('foto_ktp', foto_ktp)
    formData.append('foto_kk', foto_kk)
    formData.append('foto_ijazah', foto_ijazah)
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateUser(username)}`,
        data: formData,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

// export const FilterUserAMRequest = (role, page, limit, keyword, dsoId) => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${FilterUserAM(role, page, limit, keyword, dsoId)}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }

// export const UserDetailRequest = (id) => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${UserDetail(id)}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }



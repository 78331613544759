<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="12" sm="12" class="p-5">
              <v-alert type="info">Data Pengguna</v-alert>
            </v-col>

            <!-- Column 1 -->
            <v-col md="4" sm="12" class="p-5">
              <v-text-field
                v-model="detailUser.nama"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.nik"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="NIK"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.kk"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nomor KK"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.tempat_lahir"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Tepat Lahir"
                required
                outlined
                dense
              ></v-text-field>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="detailUser.tanggal_lahir"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="detailUser.tanggal_lahir"
                    label="Tanggal Lahir"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="detailUser.tanggal_lahir" no-title scrollable :max="nowDate">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Batal
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(detailUser.tanggal_lahir)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-text-field
                v-model="detailUser.jk"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Jaminan Kesehatan"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.status"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Status"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.hobi"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Hobi"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Column 2 -->
            <v-col md="4" sm="12" class="p-5">
              <v-text-field
                v-model="detailUser.pendidikan"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Pendidikan"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.kontak"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kontak"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.nip"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="NIP"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.dealer"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Dealer"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.cabang"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Cabang"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.soh"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="SOH"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.kursus"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kursus"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Column 3 -->
            <v-col md="4" sm="12" class="p-5">
              <v-text-field
                v-model="detailUser.facebook"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Facebook"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.instagram"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Instagram"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.twitter"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Twitter"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="detailUser.youtube"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Youtube"
                required
                outlined
                dense
              ></v-text-field>
              <v-file-input
                v-model="detailUser.foto_ktp"
                accept="image/*"
                label="Foto KTP"
                outlined
                dense
                required
                prepend-icon="mdi-camera"
              ></v-file-input>
              <v-file-input
                v-model="detailUser.foto_kk"
                accept="image/*"
                label="Foto KK"
                outlined
                dense
                required
                prepend-icon="mdi-camera"
              ></v-file-input>
              <v-file-input
                v-model="detailUser.foto_ijazah"
                accept="image/*"
                label="Foto Ijazah"
                outlined
                dense
                required
                prepend-icon="mdi-camera"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
            >
              Submit
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>


    <Alert
      :status="isErrorUpdateUser && errorCodeUpdateUser !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseUpdateUser"
    />

  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UserControllers} from "../../controllers/UserControllers.js";
import Swal from "sweetalert2";

export default {
  name: "update-user",
  data: () => ({
    user: new UserControllers(),
    valid: true,
    title: "Update Pengguna",

    isLoading: false,
    fullPage: true,

    selectScope: {
      page: 1,
      limit: 600,
    }
  }),
  menu: false,
  nowDate: new Date().toISOString().slice(0,10),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // users
    isErrorUpdateUser() {
      return this.user.isError;
    },

    errorCodeUpdateUser(){
      return this.user.errorCode;
    },

    errorCauseUpdateUser() {
      return this.user.errorCause;
    },

    isLoadingUpdateUser() {
      return this.user.isLoading;
    },

    detailUser(){
      return this.$route.params.data
    },
    username(){
      return decodeURI(this.$route.params.username)
    }
  },


  methods: {
    async getUser() {
      return this.user.getUserDetail(
        this.$route.params.id
      );
    },
    back() {
      this.$router.go(-1);
    },

    async submitProcess(
    username,
    nama,
    nik,
    kk,
    tempat_lahir,
    tanggal_lahir,
    jk,
    status,
    hobi,
    pendidikan,
    kontak,
    nip,
    dealer,
    cabang,
    soh,
    kursus,
    facebook,
    instagram,
    twitter,
    youtube,
    foto_ktp,
    foto_kk,
    foto_ijazah
    ) {
      return this.user.updateUserList(
        this.username,
        username,
        nama,
        nik,
        kk,
        tempat_lahir,
        tanggal_lahir,
        jk,
        status,
        hobi,
        pendidikan,
        kontak,
        nip,
        dealer,
        cabang,
        soh,
        kursus,
        facebook,
        instagram,
        twitter,
        youtube,
        foto_ktp,
        foto_kk,
        foto_ijazah
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.detailUser.nama,
          this.detailUser.nik,
          this.detailUser.kk,
          this.detailUser.tempat_lahir,
          this.detailUser.tanggal_lahir,
          this.detailUser.jk,
          this.detailUser.status,
          this.detailUser.hobi,
          this.detailUser.pendidikan,
          this.detailUser.kontak,
          this.detailUser.nip,
          this.detailUser.dealer,
          this.detailUser.cabang,
          this.detailUser.soh,
          this.detailUser.kursus,
          this.detailUser.facebook,
          this.detailUser.instagram,
          this.detailUser.twitter,
          this.detailUser.youtube,
          this.detailUser.foto_ktp,
          this.detailUser.foto_kk,
          this.detailUser.foto_ijazah,
        );

        if (this.isErrorUpdateUser) {
          this.isLoading = false;
          this.isErrorUpdateUser = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateUser,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diupdate",
          });
          this.back()
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorUpdateUser = false;
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
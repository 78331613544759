import axios from "axios";
import { BrandList, DealerList, CabangList} from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
// import { getToken } from "../external/functions/Cookies.js";
// const headers = {
//     'Authorization': `Bearer ${getToken()}`
// };

export const BrandListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${BrandList()}`
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DealerListRequest = (brand) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${DealerList(brand)}`
    }).then((response) => {
        return response;
    });

    return resp;
}
export const CabangListRequest = (dealer) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CabangList(dealer)}`
    }).then((response) => {
        return response;
    });

    return resp;
}





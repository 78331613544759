export const defineRole = (role, menu) => {
    const roleStored = {
        "admin.it": {
            "brand": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "user": {
                create: true,
                read: true,
                update: true,
                del: true,
                dso: false,
                filter:false
            },
            "cabang": {
                create: true,
                read: true,
                update: true,
                del: true,
                dso: false,
                filter:false
            },
            "car-type": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "sa": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter:false,
                verify: true,
            },
            "item-care":{
                create: true,
                read: true,
                update: true,
                del: true,
            }
        },
        "admin.part":{
            "opname":{
                create: true,
                read: true,
                update: true,
                del: true,
            }
        },
        "default": {},
    }

    return roleStored[role][menu];
}
import axios from "axios";
import FormData from 'form-data';
// import qs from 'qs'
import { CarList, CreateCar, DeleteCar, UpdateCar} from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const CarListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CarList()}`,
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateCarRequest = (
    brand, 
    model, 
    kode
) => {
    let formData = new FormData()
    formData.append('brand',brand)
    formData.append('model',model)
    formData.append('kode',kode)
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateCar()}`,
        data: formData,
        headers: { 
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${getToken()}`
        }
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteCarRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteCar(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateCarRequest = (
    id,
    brand, 
    model, 
    kode
) => {
    let formData = new FormData()
    formData.append('brand',brand)
    formData.append('model',model)
    formData.append('kode',kode)
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateCar(id)}`,
        data: formData,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

// export const FilterCarAMRequest = (role, page, limit, keyword, dsoId) => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${FilterCarAM(role, page, limit, keyword, dsoId)}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }

// export const CarDetailRequest = (id) => {
//     const resp = axios({
//         method: 'get',
//         url: `${BaseUrl()}${CarDetail(id)}`,
//         headers: headers
//     }).then((response) => {
//         return response;
//     });

//     return resp;
// }



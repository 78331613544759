import axios from "axios";
// import FormData from 'form-data';
// import qs from 'qs'
import { OpnameList } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
// import { getToken } from "../external/functions/Cookies.js";
// const headers = {
//     'Authorization': `Bearer ${getToken()}`
// };
export const OpnameListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${OpnameList()}`,
        // headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}
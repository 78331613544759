<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="6" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-btn
          v-if="getRole.role.create"
          class="float-right"
          depressed
          color="primary"
          @click="createUser"
          block
        >
          Tambah Pengguna
        </v-btn>
      </v-col>
      <!-- <v-col md="2" sm="12">
        <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh Excel
        </v-btn>
      </v-col> -->
      <v-col md="3" sm="12">
        <v-text-field
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
          block
        ></v-text-field
      ></v-col>
    </v-row>
    <!-- <v-row>
      <v-col md="3" sm="12" v-if="getRole.role.filter">
          <v-autocomplete
            :items="allUserAM"
            v-model="dso"
            item-text="name"
            item-value="id"
            @change="pickArea"
            label="Pilih DSO"
            dense
            block
            right
            outlined
          ></v-autocomplete>
        </v-col>
    </v-row> -->

    <v-row v-if="!isLoadingUser">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nomor</th>
                  <th class="text-left">Nama</th>
                  <th class="text-left">Username</th>
                  <th class="text-left">Email</th>
                  <!-- <th class="text-left">Profile</th>
                  <th class="text-left">Batch</th>
                  <th class="text-left">Parent</th> -->
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in users" :key="index">
                  <td>{{index + pages.start}}</td>
                  <td>{{item.nama? item.nama : '-'}}</td>
                  <td>{{item.username}}</td>
                  <td>
                    {{item.email? item.email : '-'}}
                  </td>
                  <!-- <td>
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                    >
                      <v-icon>mdi-card-account-details</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                      @click="getdetail(item.id)"
                    >
                      <v-icon>mdi-account-hard-hat</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                      @click="getdetail(item.id)"
                    >
                      <v-icon>mdi-human-male-female</v-icon>
                    </v-btn>
                  </td> -->
                  <td v-if="getRole.role.update">
                    <v-btn
                      small
                      icon
                      color="warning"
                      dark
                      @click="update(item.username, item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn
                      small
                      icon
                      color="error"
                      dark
                      @click="deleteUser(item.username)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoadingUser" :is-full-page="fullPage"></loading>

    <!-- <Detail :isShow="isDetail" :data="userDetail" @hide="updateModal" /> -->

    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseUser"
    />

   <Alert
      :status="isErrorDelete"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseDelete"
    />

    <!--  <Alert
      :status="isErrorDownload && isErrorCodeDownload !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="isErrorCauseDownload"
    /> -->
  </v-container>
</template>
<script>
import { UserControllers } from "../../controllers/UserControllers.js";
// import { UserControllers } from "../../controllers/UserControllers";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
// import Detail from "./user-detail";
import Swal from "sweetalert2";
// import moment from "moment";
// import XLSX from "xlsx";
// import { getRole} from "../../external/functions/Cookies";

export default {
  name: "dealer-cabang-list",
  data: () => ({
    user: new UserControllers(),
    // userControllers: new UserControllers(),
    title: "Daftar Pengguna",
    page : 1,
    limit : 25,

    pages: {
      start: 1,
      end: 25,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,

    search: {
      debounce: null,
    },

    dialogDownload: false,

    searchField: "",

    isZoom : false,
    imageZoomed : '',
  }),

  components: {
    Alert,
    Loading,
    // Detail,
  },

  created() {
      this.getUser()
  },

  watch:{
    searchField(){
      this.pages.start = 1
      this.pages.end = 25
    }
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    users() {
      let userArr = this.user.userList
      let userList 
      if(this.searchField){
        const query = new RegExp(`${this.searchField}`, "i")
        let userFilter = userArr.filter(datum => datum.username.match(query) )
        userList = userFilter.slice(this.pages.start-1,this.pages.end)
      }else{
        userList = userArr.slice(this.pages.start-1,this.pages.end)
      }

      if(userList){
        return userList
      }

      return null;
    },

  //   userDetail() {
  //     console.log(this.user);
  //     return this.user.userDetail;
  //   },

  //   allUserAM() {
  //     let arrDso = this.userControllers.userListAM
  //     arrDso.unshift({name:'Semua DSO',id:''})
  //     return arrDso
  //   },

    isErrorCode() {
      return this.user.errorCode;
    },

    isError() {
      return this.user.isError;
    },

    errorCauseUser() {
      return this.user.errorCause;
    },

    isLoadingUser() {
      return this.user.isLoading;
    },

    // delete
    isErrorDelete() {
      return this.user.isErrorDelete;
    },

    errorCauseDelete() {
      return this.user.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.user.isLoading;
    },

  //   // download
  //   dataDownload() {
  //     return this.user.userDownload;
  //   },

  //   isErrorDownload() {
  //     return this.user.errorDownload;
  //   },

  //   isErrorCauseDownload() {
  //     return this.user.errorCauseDownload;
  //   },

  //   isLoadingDownload() {
  //     return this.user.loadingDownload;
  //   },

  //   isErrorCodeDownload() {
  //     return this.user.errorCodeDownload;
  //   },
  },

  methods: {
    async getUser() {
      return this.user.getUserList();
    },

    async formerPage() {
      if (this.pages.start > this.limit) {
        this.isLoading = this.isLoadingUser;
        this.pages.start -= this.limit;
        this.pages.end -= this.limit;

        this.userTable.page -= 1;
      } else {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
      }
    },

    async nextPage() {
      let min = this.pages.start + this.limit
      const userArr = this.user.userList
      let lengthData = userArr.length
      if(this.searchField){
        const query = new RegExp(`${this.searchField}`, "i")
        let userFilter = userArr.filter(datum => datum.dealer.match(query) || datum.cabang.match(query) || datum.brand.match(query))
        lengthData = userFilter.length
      }
      if (lengthData>min){
        this.pages.start += this.limit;
        this.pages.end += this.limit;

        this.userTable.page += 1;
      } else {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    createUser() {
      this.$router.push({
        name: "Create User",
      });
    },

  //   getdetail(id) {
  //     console.log(this.user);
  //     this.isDetail = true;
  //     return this.user.getUserDetail(id);
  //   },

  //   updateModal(status) {
  //     this.isDetail = status;
  //   },

    deleteUser(username) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.user.deleteUser(username);
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.pages.start = 1
          this.pages.end = 25
          return this.user.getUserList()
        });
    },

    update(username, data) {
      this.$router.push({
        name: "Update User",
        params: {
          username: username,
          data: data
        },
      });
    },

  //   async debounceSearch() {
  //     this.pages.start = 1;
  //     this.pages.end = 10;

  //     this.userTable.page = 1;
  //     this.userTable.limit = 10;
  //     this.userTable.keyword = this.searchField;

  //     await this.getUsers(
  //       this.userTable.role,
  //       this.userTable.page,
  //       this.userTable.limit,
  //       this.userTable.keyword,
  //       this.dso
  //     );
  //   },

  //   debounceSearchEmpty(event) {
  //     clearTimeout(this.debounce);
  //     this.search.debounce = setTimeout(async () => {
  //       if (event.length === 0) {
  //         this.pages.start = 1;
  //         this.pages.end = 10;

  //         this.userTable.page = 1;
  //         this.userTable.limit = 10;
  //         this.userTable.keyword = event;

  //         await this.getUsers(
  //           this.userTable.page,
  //           this.userTable.limit,
  //           this.userTable.keyword,
  //           this.dso
  //         );
  //       }
  //     }, 500);
  //   },

  //   normalDate(val) {
  //     return moment(val).format("DD-MM-YYYY h:mm:ss");
  //   },

  //   async download() {
  //     await this.user.downloadUserList(
  //       this.userTable.page,
  //       this.userTable.limit,
  //       this.userTable.keyword,
  //       this.dso
  //     );

  //     if (this.dataDownload.length > 0) {
  //       const dataDownload = [];
  //       for (const item of this.dataDownload) {
  //         dataDownload.push({
  //           "nama user": item.name,
  //           brand: item.brand.name,
  //           kota: item.address.district,
  //           alamat: item.address
  //             ? `${item.address.street}, ${item.address.village}, ${item.address.subDistrict}, ${item.address.district}, ${item.address.province}, ${item.address.zipCode}`
  //             : "",
  //           kategori: item.category,
  //           "login terakhir": this.normalDate(item.lastLogin),
  //           "dibuat pada": this.normalDate(item.createdAt),
  //           "diperbaharui pada": this.normalDate(item.updatedAt),
  //         });
  //       }

  //       const data = XLSX.utils.json_to_sheet(dataDownload);
  //       const wb = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(wb, data, "data");
  //       XLSX.writeFile(wb, `Report User List.xlsx`);
  //     } else {
  //       Swal.fire({
  //         text: "Data Tidak ditemukan",
  //         icon: "warning",
  //       });
  //     }
  //   },
  },
};
</script>
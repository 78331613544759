<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="12" sm="12">
              <v-alert type="info">Data Tipe Mobil</v-alert>
            </v-col>
            <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="brand"
                :items="brands"
                item-text="brand"
                item-value="brand"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Brand"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-text-field
                v-model="model"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Model Mobil"
                required
                outlined
                dense
                @keyup="uppercase"
              ></v-text-field>
            
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="kode"
                :items="kodeArr"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kode"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>


    <!-- Error Brands -->
    <Alert
      :status="isErrorGetBrands && errorCodeGetBrands !== 404"
      icon="error"
      title="KECarLAHAN"
      :msg="errorCauseGetBrands"
    />
  </v-container>
</template>
<script>
import { CarControllers } from "../../controllers/CarControllers.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { DropdownControllers } from "../../controllers/DropdownControllers.js";
import Swal from "sweetalert2";

export default {
  name: "create-sa",
  data: () => ({
    Car : new CarControllers,
    dropdown : new DropdownControllers,
    valid: true,
    title: "Ubah Tipe Mobil",

    isLoading: false,
    fullPage: true,

    brand: "",
    model: "",
    kode: "",

    selectScope: {
      page: 1,
      limit: 600,
    },
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // Cars
    isErrorUpdateCar() {
      return this.Car.isError;
    },

    errorCauseUpdateCar() {
      return this.Car.errorCause;
    },

    isLoadingUpdateCar() {
      return this.Car.isLoading;
    },

    brands(){
      return this.dropdown.brandList
    },

    errorCodeGetBrands() {
      return this.dropdown.errorCodeBrand;
    },

    isErrorGetBrands() {
      return this.dropdown.isErrorBrand;
    },

    errorCauseGetBrands() {
      return this.dropdown.errorCauseBrand;
    },

    kodeArr(){
      return ['A','B','C','D','E','F','G','H', 'I', 'J', 'K', 'L', 'M',
       'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    }  
  },

  created() {
  this.getBrandList()
  this.brand = this.$route.params.data.brand
  this.model = this.$route.params.data.model
  this.kode = this.$route.params.data.kode
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    uppercase() {
      this.model = this.model.toUpperCase();
    },

    getBrandList() {
      return this.dropdown.getBrandList();
    },
    
    async submitProcess(
      brand,
      model,
      kode
    ) {
      return this.Car.updateCarList(
        brand,
        model,
        kode
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.brand,
          this.model,
          this.kode,
        );

        if (this.isErrorCreateCar) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateVenue,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "SUKSES",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
export const defineMenu = (role) => {
    const menusStored = {
        "admin.it": [
            {
                title: "Pengguna",
                icon: "mdi-account-circle",
                path: "/user-list",
                haveChild: false,
                submenus: [],
            },
            {
                title: "Dealer Cabang",
                icon: "mdi-tools",
                path: "/dealer-cabang-list",
                haveChild: false,
                submenus: []
            }, 
            {
                title: "Service Advisor",
                icon: "mdi-face-agent",
                path: "/sa-list",
                haveChild: false,
                submenus: [],
            }, 
            {
                title: "Tipe Mobil",
                icon: "mdi-car",
                path: "/car-type-list",
                haveChild: false,
                submenus: [],
            },
            {
                title: "Pekerjaan Item",
                icon: "mdi-toolbox",
                haveChild: true,
                submenus: [{
                    title: "Pekerjaan Item",
                    icon: "mdi-toolbox",
                    children: [
                        {
                            title: "Perawatan Precheck",
                            path: "/perawatan-precheck-list",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Perawatan Postcheck",
                            path: "/perawatan-postcheck-list",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Pergantian Precheck",
                            path: "/pergantian-precheck-list",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Pergantian Postcheck",
                            path: "/pergantian-postcheck-list",
                            haveChild: false,
                            submenusChild: [],
                        },
                    ],
                }]
            },
            {
                title: "Gudang Stok",
                icon: "mdi-warehouse",
                haveChild: true,
                submenus: [{
                    title: "Gudang Stok",
                    icon: "mdi-warehouse",
                    children: [
                        {
                            title: "Gudang Master",
                            path: "/warehouse-list",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Gudang Transit",
                            path: "/warehouse-transit-list",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Gudang Cabang",
                            path: "/warehouse-branch-list",
                            haveChild: false,
                            submenusChild: [],
                        }
                    ],
                }]
            }, 

        ],
        "admin.part":[
            {
                title: "Stock Opname",
                icon: "mdi-warehouse",
                path: "/opname-list",
                haveChild: false,
                submenus: [],
            },
        ],
        "default": []
    }

    return menusStored[role];
}
<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="6" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-btn
          v-if="getRole.role.create"
          class="float-right"
          depressed
          color="primary"
          @click="createPergantianPostcheck"
          block
        >
          Tambah Item
        </v-btn>
      </v-col>
      <!-- <v-col md="2" sm="12">
        <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh Excel
        </v-btn>
      </v-col> -->
      <v-col md="3" sm="12">
        <v-text-field
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
          block
        ></v-text-field
      ></v-col>
    </v-row>
    <!-- <v-row>
      <v-col md="3" sm="12" v-if="getRole.role.filter">
          <v-autocomplete
            :items="allPergantianPostcheckAM"
            v-model="dso"
            item-text="name"
            item-value="id"
            @change="pickArea"
            label="Pilih DSO"
            dense
            block
            right
            outlined
          ></v-autocomplete>
        </v-col>
    </v-row> -->

    <v-row v-if="!isLoadingPergantianPostcheck">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nomor</th>
                  <th class="text-left">Dealer</th>
                  <th class="text-left">Keterangan</th>
                  <th class="text-left">Harga A</th>
                  <th class="text-left">Harga B</th>
                  <th class="text-left">Harga C</th>
                  <th class="text-left">Harga D</th>
                  <th class="text-left">Harga E</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in pergantianpostchecks" :key="index">
                  <td>{{index + pages.start}}</td>
                  <td>{{item.dealer? item.dealer : '-'}}</td>
                  <td>{{item.keterangan? item.keterangan : '-'}}</td>
                  <td>{{item.Harga_a? item.Harga_a : '-'}}</td>
                  <td>{{item.Harga_b? item.Harga_b : '-'}}</td>
                  <td>{{item.Harga_c? item.Harga_c : '-'}}</td>
                  <td>{{item.Harga_d? item.Harga_d : '-'}}</td>
                  <td>{{item.Harga_e? item.Harga_e : '-'}}</td>
                  <td v-if="getRole.role.update">
                    <v-btn
                      small
                      icon
                      color="warning"
                      dark
                      @click="update(item.id, item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn
                      small
                      icon
                      color="error"
                      dark
                      @click="deletePergantianPostcheck(item.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoadingPergantianPostcheck" :is-full-page="fullPage"></loading>

    <!-- <Detail :isShow="isDetail" :data="pergantianpostcheckDetail" @hide="updateModal" /> -->

    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCausePergantianPostcheck"
    />

    <Alert
      :status="isErrorDelete"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseDelete"
    />

   <!--  <Alert
      :status="isErrorDownload && isErrorCodeDownload !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="isErrorCauseDownload"
    /> -->
  </v-container>
</template>
<script>
import { PergantianPostcheckControllers } from "../../controllers/PergantianPostcheckControllers.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
// import Detail from "./pergantianpostcheck-detail";
import Swal from "sweetalert2";
// import moment from "moment";
// import XLSX from "xlsx";
// import { getRole} from "../../external/functions/Cookies";

export default {
  name: "dealer-cabang-list",
  data: () => ({
    pergantianpostcheck: new PergantianPostcheckControllers(),
    title: "Daftar Item Pergantian Postcheck",
    page : 1,
    limit : 25,

    pages: {
      start: 1,
      end: 25,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,

    search: {
      debounce: null,
    },

    dialogDownload: false,

    searchField: "",

    isZoom : false,
    imageZoomed : '',
  }),

  components: {
    Alert,
    Loading,
    // Detail,
  },

  created() {
      this.getPergantianPostcheck()
  },

  watch:{
    searchField(){
      this.pages.start = 1
      this.pages.end = 25
    }
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    pergantianpostchecks() {
      let pergantianpostcheckArr = this.pergantianpostcheck.pergantianpostcheckList
      let pergantianpostcheckList 
      if(this.searchField){
        const query = new RegExp(`${this.searchField}`, "i")
        let pergantianpostcheckFilter = pergantianpostcheckArr.filter(datum => datum.dealer.match(query) || datum.keterangan.match(query))
        pergantianpostcheckList = pergantianpostcheckFilter.slice(this.pages.start-1,this.pages.end)
      }else{
        pergantianpostcheckList = pergantianpostcheckArr.slice(this.pages.start-1,this.pages.end)
      }

      if(pergantianpostcheckList){
        return pergantianpostcheckList
      }

      return null;
    },

  //   pergantianpostcheckDetail() {
  //     console.log(this.pergantianpostcheck);
  //     return this.pergantianpostcheck.pergantianpostcheckDetail;
  //   },

  //   allPergantianPostcheckAM() {
  //     let arrDso = this.pergantianpostcheckControllers.pergantianpostcheckListAM
  //     arrDso.unshift({name:'Semua DSO',id:''})
  //     return arrDso
  //   },

    isErrorCode() {
      return this.pergantianpostcheck.errorCode;
    },

    isError() {
      return this.pergantianpostcheck.isError;
    },

    errorCausePergantianPostcheck() {
      return this.pergantianpostcheck.errorCause;
    },

    isLoadingPergantianPostcheck() {
      return this.pergantianpostcheck.isLoading;
    },

  //   // delete
  //   isErrorDelete() {
  //     return this.pergantianpostcheck.isErrorDelete;
  //   },

  //   errorCauseDelete() {
  //     return this.pergantianpostcheck.errorCauseDelete;
  //   },

  //   isLoadingDelete() {
  //     return this.pergantianpostcheck.isLoading;
  //   },

  //   // download
  //   dataDownload() {
  //     return this.pergantianpostcheck.pergantianpostcheckDownload;
  //   },

  //   isErrorDownload() {
  //     return this.pergantianpostcheck.errorDownload;
  //   },

  //   isErrorCauseDownload() {
  //     return this.pergantianpostcheck.errorCauseDownload;
  //   },

  //   isLoadingDownload() {
  //     return this.pergantianpostcheck.loadingDownload;
  //   },

  //   isErrorCodeDownload() {
  //     return this.pergantianpostcheck.errorCodeDownload;
  //   },
  },

  methods: {
    async getPergantianPostcheck() {
      return this.pergantianpostcheck.getPergantianPostcheckList();
    },

    async formerPage() {
      if (this.pages.start > this.limit) {
        this.isLoading = this.isLoadingPergantianPostcheck;
        this.pages.start -= this.limit;
        this.pages.end -= this.limit;

        this.pergantianpostcheckTable.page -= 1;
      } else {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
      }
    },

    async nextPage() {
      let min = this.pages.start + this.limit
      const pergantianpostcheckArr = this.pergantianpostcheck.pergantianpostcheckList
      let lengthData = pergantianpostcheckArr.length
      if(this.searchField){
        const query = new RegExp(`${this.searchField}`, "i")
        let pergantianpostcheckFilter = pergantianpostcheckArr.filter(datum => datum.dealer.match(query) || datum.keterangan.match(query))
        lengthData = pergantianpostcheckFilter.length
      }
      if (lengthData>min){
        this.pages.start += this.limit;
        this.pages.end += this.limit;

        this.pergantianpostcheckTable.page += 1;
      } else {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    createPergantianPostcheck() {
      this.$router.push({
        name: "Create Pergantian Postcheck",
      });
    },

  //   getdetail(id) {
  //     console.log(this.pergantianpostcheck);
  //     this.isDetail = true;
  //     return this.pergantianpostcheck.getPergantianPostcheckDetail(id);
  //   },

  //   updateModal(status) {
  //     this.isDetail = status;
  //   },

    deletePergantianPostcheck(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.pergantianpostcheck.deletePergantianPostcheck(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.pergantianpostcheck.getPergantianPostcheckList();
        });
    },

    update(id, data) {
      this.$router.push({
        name: "Update Pergantian Postcheck",
        params: {
          id: id,
          data: data
        },
      });
    },

  //   async debounceSearch() {
  //     this.pages.start = 1;
  //     this.pages.end = 10;

  //     this.pergantianpostcheckTable.page = 1;
  //     this.pergantianpostcheckTable.limit = 10;
  //     this.pergantianpostcheckTable.keyword = this.searchField;

  //     await this.getPergantianPostchecks(
  //       this.pergantianpostcheckTable.role,
  //       this.pergantianpostcheckTable.page,
  //       this.pergantianpostcheckTable.limit,
  //       this.pergantianpostcheckTable.keyword,
  //       this.dso
  //     );
  //   },

  //   debounceSearchEmpty(event) {
  //     clearTimeout(this.debounce);
  //     this.search.debounce = setTimeout(async () => {
  //       if (event.length === 0) {
  //         this.pages.start = 1;
  //         this.pages.end = 10;

  //         this.pergantianpostcheckTable.page = 1;
  //         this.pergantianpostcheckTable.limit = 10;
  //         this.pergantianpostcheckTable.keyword = event;

  //         await this.getPergantianPostchecks(
  //           this.pergantianpostcheckTable.page,
  //           this.pergantianpostcheckTable.limit,
  //           this.pergantianpostcheckTable.keyword,
  //           this.dso
  //         );
  //       }
  //     }, 500);
  //   },

  //   normalDate(val) {
  //     return moment(val).format("DD-MM-YYYY h:mm:ss");
  //   },

  //   async download() {
  //     await this.pergantianpostcheck.downloadPergantianPostcheckList(
  //       this.pergantianpostcheckTable.page,
  //       this.pergantianpostcheckTable.limit,
  //       this.pergantianpostcheckTable.keyword,
  //       this.dso
  //     );

  //     if (this.dataDownload.length > 0) {
  //       const dataDownload = [];
  //       for (const item of this.dataDownload) {
  //         dataDownload.push({
  //           "nama pergantianpostcheck": item.name,
  //           brand: item.brand.name,
  //           kota: item.address.district,
  //           alamat: item.address
  //             ? `${item.address.street}, ${item.address.village}, ${item.address.subDistrict}, ${item.address.district}, ${item.address.province}, ${item.address.zipCode}`
  //             : "",
  //           kategori: item.category,
  //           "login terakhir": this.normalDate(item.lastLogin),
  //           "dibuat pada": this.normalDate(item.createdAt),
  //           "diperbaharui pada": this.normalDate(item.updatedAt),
  //         });
  //       }

  //       const data = XLSX.utils.json_to_sheet(dataDownload);
  //       const wb = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(wb, data, "data");
  //       XLSX.writeFile(wb, `Report PergantianPostcheck List.xlsx`);
  //     } else {
  //       Swal.fire({
  //         text: "Data Tidak ditemukan",
  //         icon: "warning",
  //       });
  //     }
  //   },
  },
};
</script>
<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="12" sm="12">
              <v-alert type="info">Data Dealer Cabang</v-alert>
            </v-col>
            <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="form.brand"
                :items="brands"
                item-text="brand"
                item-value="brand"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Brand"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-if="form.brand == 'Other'"
                v-model="brandOther"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Tambah Brand Baru"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="form.dealer"
                :items="dealers"
                item-text="dealer"
                item-value="dealer"
                dense
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Dealer"
                required
                outlined
              ></v-autocomplete>

              <v-text-field
                v-if="form.dealer == 'Other'"
                v-model="dealerOther"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Tambah Dealer Baru"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="form.cabang"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Cabang"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col md="4" sm="12" class="p-5">
              <v-textarea
                v-model="form.alamat"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Alamat"
                required
                outlined
                dense
              ></v-textarea>

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="form.waktu"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.waktu"
                    label="Waktu Masuk"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu"
                  format="24hr"
                  v-model="form.waktu"
                  use-seconds
                  full-width
                  @click:second="$refs.menu.save(form.waktu)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col md="4" sm="12" class="p-5">

              <v-autocomplete
                v-model="form.kode"
                :items="kodeArr"
                item-text="key"
                item-value="val"
                dense
                label="Kode Wilayah"
                outlined
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.kodeInt"
                :items="kodeIntArr"
                dense
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kode Wilayah Internal"
                required
                outlined
              ></v-autocomplete>

              <v-file-input
                v-model="form.foto"
                accept="image/*"
                label="Logo"
                outlined
                dense
                required
                prepend-icon="mdi-camera"
              ></v-file-input>


              <!-- <v-card>
                <v-card-title> Upload Logo</v-card-title>
                <v-card-text>
                  <input @change="changeMedia" ref="myFileInput" type="file" accept="image/*" />
                  <br /><br />
                  <v-row>
                    <v-col
                      v-for="(item, index) in pics"
                      :key="index"
                      class="d-flex child-flex"
                      cols="4"
                    >
                      <v-img
                        :src="item"
                        :lazy-src="item"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                      <v-icon color="error" style="cursor: pointer;" @click="deletePic(index)">mdi-close</v-icon>
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card> -->
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Users -->
    <Alert
      :status="isErrorGetBrands && errorCodeGetBrands !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetBrands"
    />

  </v-container>
</template>
<script>
import { DealerCabangControllers } from "../../controllers/DealerCabangControllers.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { DropdownControllers } from "../../controllers/DropdownControllers.js";
import Swal from "sweetalert2";

export default {
  name: "create-dealer-cabang",
  data: () => ({
    dealerCabang : new DealerCabangControllers,
    dropdown : new DropdownControllers,
    valid: true,
    title: "Buat Dealer Cabang",

    isLoading: false,
    fullPage: true,
    isBrand : false,
    isDealer : false,
    brandOther: "",
    dealerOther: "",

    form: {
      brand: "",
      dealer: "",
      cabang: "",
      alamat: "",
      waktu: "",
      kode: "",
      kodeInt : "",
      foto:null
    },

    menu: false,

    selectScope: {
      page: 1,
      limit: 600,
    },
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    kodeArr(){
      let val = [null,1,2,3,4,5,6,7,8,9,10]
      let key = ['Tanpa Kode',1,2,3,4,5,6,7,8,9,10]
      let arr = []
      for(let i = 0;i<val.length;i++){
        arr.push({key:key[i], val:val[i]})
      }
      return arr
    },
    kodeIntArr(){
      return [1,2,3,4,5,6,7,8,9,10]
    },
    chosenBrand(){
      return this.form.brand;
    },
    chosenDealer(){
      return this.form.dealer
    },
  // Dealer Cabang
    isErrorCreateDealerCabang() {
      return this.dealerCabang.isError;
    },

    errorCauseCreateDealerCabang() {
      return this.dealerCabang.errorCause;
    },

    isLoadingCreateDealerCabang() {
      return this.dealerCabang.isLoading;
    },

    // brands
    brands() {
      let brandList = []

      if(this.dropdown.brandList){
        brandList.push(...this.dropdown.brandList)
        brandList.push({brand: 'Other'})
        return brandList
      }
      brandList.push({brand: 'Other'})
      return brandList;
    },

    errorCodeGetBrands() {
      return this.dropdown.errorCodeBrand;
    },

    isErrorGetBrands() {
      return this.dropdown.isErrorBrand;
    },

    errorCauseGetBrands() {
      return this.dropdown.errorCauseBrand;
    },

    // dealsers
    dealers() {
      let dealerList = this.dropdown.dealerList

      if(dealerList){
        dealerList.push({brand : this.form.brand, dealer: 'Other'})
        return dealerList
      }
      return [];
    },

    errorCodeGetDealers() {
      return this.dropdown.errorCodeDealer;
    },

    isErrorGetDealers() {
      return this.dropdown.isErrorDealer;
    },

    errorCauseGetDealers() {
      return this.dropdown.errorCauseDealer;
    },
  },

  created() {
    this.getBrandList();
  },

  watch:{
    chosenBrand(newBrand){
      this.getDealerList(newBrand)
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    getBrandList() {
      return this.dropdown.getBrandList();
    },

    getDealerList(brand) {
      return this.dropdown.getDealerList(brand);
    },

    // changeMedia(){
    //   return
    // },


    async submitProcess(
      brand,
      dealer,
      cabang,
      alamat,
      waktu,
      kode,
      kodeInt,
      foto
    ) {
      return this.dealerCabang.createDealerCabangList(
        brand,
        dealer,
        cabang,
        alamat,
        waktu,
        kode,
        kodeInt,
        foto
      );
    },


    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.brand == 'Other'? this.brandOther: this.form.brand,
          this.form.dealer == 'Other'? this.dealerOther: this.form.dealer,
          this.form.cabang,
          this.form.alamat,
          String(this.form.waktu),
          this.form.kode,
          this.form.kodeInt,
          this.form.foto
        );

        if (this.isErrorCreateDealerCabang) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateDealerCabang,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "SUKSES",
            text: "Data berhasil dibuat",
          });
          // this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
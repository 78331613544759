import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {}

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1D83C6',
                blue:  '#1D83C6',
                darken: '#1D83C6',
                info: '#1D83C6',
                success: '#1D83C6'
            },
            dark: {
                primary: '#1D83C6',
                blue:  '#1D83C6',
                darken: '#1D83C6',
            },
        },
    },
})

export default new Vuetify(opts, vuetify)
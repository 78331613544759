import {
    PergantianPrecheckListRequest,
    CreatePergantianPrecheckRequest,
    DeletePergantianPrecheckRequest,
    UpdatePergantianPrecheckRequest,
    // PergantianPrecheckDetailRequest
} from "../request/PergantianPrecheckRequest.js";

export class PergantianPrecheckControllers {
    pergantianprecheckList = new Array();
    pergantianprecheckDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getPergantianPrecheckList() {
        this.setLoading(true);
        const resp = PergantianPrecheckListRequest()
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.status);
                this.setPergantianPrecheckList(response.data);
                return true;
            }).catch(err => {
                console.log(err.response)
                this.setError(true);
                this.setErrorCause(err.statusText);
                this.setErrorcode(err.status);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    // getPergantianPrecheckDetailEdit(id) {
    //     this.setLoading(true);
    //     const resp = PergantianPrecheckDetailRequest(id)
    //         .then((response) => {
    //             this.setError(false);
    //             this.setErrorcode(response.data.code);
    //             this.setPergantianPrecheckDetail(response.data.data);
    //             return true;
    //         }).catch(err => {
    //             this.setError(true);
    //             this.setErrorCause(err.response.data.message);
    //             this.setErrorcode(err.response.data.code);
    //             return false;
    //         }).finally(() => {
    //             this.setLoading(false);
    //         });

    //     return resp;
    // }

    // downloadPergantianPrecheckList(role, page, limit, keyword, dsoId) {
    //     this.setLoadingDownload(true);
    //     const resp = FilterPergantianPrecheckAMRequest(role, page, limit, keyword, dsoId)
    //         .then((response) => {
    //             this.setErrorDownload(false);
    //             this.setErrorcodeDownload(response.data.code);
    //             this.setPergantianPrecheckListDownload(response.data.data);
    //         }).catch(err => {
    //             this.setPergantianPrecheckListDownload([])
    //             this.setErrorDownload(true);
    //             this.setErrorCauseDownload(err.response.data.message);
    //             this.setErrorcodeDownload(err.response.data.code);
    //         }).finally(() => {
    //             this.setLoadingDownload(false);
    //         });

    //     return resp;
    // }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setPergantianPrecheckListDownload(data) {
        this.pergantianprecheckDownload = data;
    }

    setPergantianPrecheckDetail(data) {
        this.pergantianprecheckDetail = data;
    }

    setPergantianPrecheckList(data) {
        this.pergantianprecheckList = data;
    }

    createPergantianPrecheckList(
        dealer,
        keterangan
    ) {
        this.setLoading(true);
        const resp = CreatePergantianPrecheckRequest(
            dealer,
            keterangan
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                let text = 'An error occurred while creating pergantian precheck'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    getPergantianPrecheckDetail(id) {
        this.pergantianprecheckDetail = this.pergantianprecheckList.find(item => {
            return item.id === id
        });
    }

    deletePergantianPrecheck(id) {
        this.setLoading(true);
        const resp = DeletePergantianPrecheckRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                let text = 'An error occurred while deleting pergantian precheck'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setErrorDelete(true);
                this.setErrorCauseDelete(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updatePergantianPrecheckList(
        id,
        dealer,
        keterangan
    ) {
        this.setLoading(true);
        const resp = UpdatePergantianPrecheckRequest(
            id,
            dealer,
            keterangan
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                let text = 'An error occurred while updating pergantian precheck'
                if(err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
                this.setError(true);
                this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}
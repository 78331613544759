<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      enable-resize-watcher
      app
      dark
      class="primary"
    >
      <template v-slot:prepend>
        <v-list-item two-line style="background-color: white; padding-left: 20px">
          <img
            src="../../assets/main-logo.png"
            alt="SNS.AC"
            width="50%"
            height="auto"
          />
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="../../assets/user.png" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ currentUser.name }}</v-list-item-title>
            <v-list-item-subtitle style="font-size: 10px">{{
              currentUser.email
            }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ currentUser.role }}</v-list-item-subtitle>
          </v-list-item-content>
          <!-- <v-list-item-content>
            <v-list-item-title>IT SNS.AC</v-list-item-title>
            <v-list-item-subtitle style="font-size: 10px">{{
              'it.snsac@gmail.com'
            }}</v-list-item-subtitle>
          </v-list-item-content> -->
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list dense nav>
        <div v-for="(menu, index) in menus" :key="index">
          <v-list-item v-if="!menu.haveChild" link @click="goTo(menu.path)">
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-for="item in menu.submenus"
            :key="item.title"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.title"
                  class="v-list-item__parent"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <div v-for="child in item.children"
              :key="child.title">
              <v-list-item
              v-if="!child.haveChild"
              link
              @click="goTo(child.path)"
            style="padding-left: 64px">
            <v-list-item-content>
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-group
              v-for="subitem in child.submenusChild"
              :key="subitem.title"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="subitem.title"
                    class="v-list-item__parent"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="grandChild in subitem.grandChildren"
                :key="grandChild.title"
                link
                @click="goTo(grandChild.path)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="grandChild.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            </div>
            
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar fixed style="height: 65px !important; width: 100%; position:fixed; z-index: 1;">
        <v-btn color="primary" @click.stop="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-toolbar-title
          style="padding-left: 10px; padding-top: 5px; padding-bottom: 5px"
        >
          <img
            src="../../assets/main-logo.png"
            alt="Logo Sns"
            width="80px"
            height="auto"
          />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <Notification /> -->
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn outlined v-on="on" color="primary" @click="action('logout')">
              <v-icon>mdi-logout</v-icon>
              KELUAR
            </v-btn>
          </template>
        </v-menu>
    </v-toolbar>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  </div>
</template>
<script>
import {
  getRole,
  getUser,
  getEmail,
  removeAuth,
  getUserId
} from "../../external/functions/Cookies.js";
import { defineMenu } from "../../external/functions/Menus.js";
// import Notification from "./notification"
import Loading from "vue-loading-overlay";
import Swal from "sweetalert2";
import { AuthControllers } from "../../controllers/AuthControllers.js";

const role = getRole();
export default {
  data: () => ({
    auth: new AuthControllers(),
    drawer: true,
    items: [
      {
        title: "Logout",
        path: "logout",
      },
    ],

    isLoading: false,
    fullPage: true,
  }),

  components: {
    // Notification,
    Loading,
  },

  computed: {
    menus() {
      return defineMenu(role);
    },

    currentUser() {
      return {
        name: getUser(),
        email: getEmail(),
        role: getRole(),
      };
    },

    isError() {
      return this.auth.isError;
    },

    errorCause() {
      return this.auth.errorCause;
    }
  },

  methods: {
    goTo(path) {
      this.$router.push(path).catch(() => {});
    },

    async goLogout() {
      return this.auth.goLogout(getUserId());
    },

    async action(path) {
      if (path === "logout") {
        Swal.fire({
          title: "Yakin akan keluar?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya, keluar!",
          cancelButtonText: "Batal",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            // await this.goLogout();

              removeAuth();
              window.location.href = window.location.origin;
            
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.v-toolbar {
  flex: none;
}
</style>
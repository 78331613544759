<template>
  <div>
    <div v-if="status && !dialog && !notAllowed" class="d-none">
      {{ message }}
    </div>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Sesi sudah habis </v-card-title>
        <v-card-text>Klik tombol di bawah untuk masuk kembali</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" block @click="loginAgain"> Masuk </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="notAllowed" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Hanya satu sesi yang diizinkan untuk akses</v-card-title>
        <v-card-text>Klik tombol di bawah untuk masuk kembali</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" block @click="loginAgain"> Masuk </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { removeAuth } from "../../external/functions/Cookies.js";
export default {
  name: "Alert",
  props: {
    status: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    msg: {
      type: String,
      default: "",
    },
  },

  computed: {
    message() {
      return Swal.fire({
        icon: this.icon,
        title: this.title,
        text: this.msg,
      });
    },

    dialog() {
      return this.msg.toLowerCase().includes('token') ? true : false;
    },

    notAllowed() {
      return this.msg.toLowerCase().includes('allowed') ? true : false;
    }
  },

  methods: {
    loginAgain() {
      removeAuth();
      window.location.href = window.location.origin;
    },
  },
};
</script>
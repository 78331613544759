import { BrandListRequest, DealerListRequest, CabangListRequest } from "../request/DropdownRequest.js";

export class DropdownControllers  {
    brandList = new Array();
    isErrorBrand= false;
    isLoadingBrand = false;
    errorCauseBrand = "";
    errorCodeBrand = 0;

    dealerList = new Array();
    isErrorDealer= false;
    isLoadingDealer = false;
    errorCauseDealer = "";
    errorCodeDealer = 0;

    cabangList = new Array();
    isErrorCabang= false;
    isLoadingCabang = false;
    errorCauseCabang = "";
    errorCodeCabang = 0;



    constructor() {}

    getBrandList() {
        this.setLoadingBrand(true);
        const resp = BrandListRequest()
        .then((response) => {
            this.setErrorBrand(false);
            this.setErrorcodeBrand(response.status);
            this.setBrandList(response.data.listbrand);
            return true;
        }).catch(err => {
            console.log(err)
            this.setErrorBrand(true);
            this.setErrorCauseBrand('An error occurred while get list dealer');
            this.setErrorcodeBrand(500);
            return false;
        }).finally(() => {
            this.setLoadingBrand(false);
        });
       
        return resp;
    }

    setBrandList(data) {
        this.brandList = data;
    }

    setLoadingBrand(status) {
        this.isLoadingBrand = status;
    }

    setErrorBrand(status) {
        this.isErrorBrand = status;
    }

    setErrorCauseBrand(err) {
        this.errorCauseBrand = err;
    }

    setErrorcodeBrand(code) {
        this.errorCodeBrand = code;
    }
    getDealerList(brand) {
        this.setLoadingDealer(true);
        const resp = DealerListRequest(brand)
        .then((response) => {
            this.setErrorDealer(false);
            this.setErrorcodeDealer(response.status);
            this.setDealerList(response.data.listdealer);
            return true;
        }).catch(err => {
            console.log(err)
            this.setErrorDealer(true);
            this.setErrorCauseDealer('An error occurred while get list dealer');
            this.setErrorcodeDealer(500);
            return false;
        }).finally(() => {
            this.setLoadingDealer(false);
        });
       
        return resp;
    }

    setDealerList(data) {
        this.dealerList = data;
    }

    setLoadingDealer(status) {
        this.isLoadingDealer = status;
    }

    setErrorDealer(status) {
        this.isErrorDealer = status;
    }

    setErrorCauseDealer(err) {
        this.errorCauseDealer = err;
    }

    setErrorcodeDealer(code) {
        this.errorCodeDealer = code;
    }

    getCabangList(dealer) {
        this.setLoadingCabang(true);
        const resp = CabangListRequest(dealer)
        .then((response) => {
            this.setErrorCabang(false);
            this.setErrorcodeCabang(response.status);
            this.setCabangList(response.data.listcabang);
            return true;
        }).catch(err => {
            console.log(err)
            this.setErrorCabang(true);
            this.setErrorCauseCabang('An error occurred while get list dealer');
            this.setErrorcodeCabang(500);
            return false;
        }).finally(() => {
            this.setLoadingCabang(false);
        });
       
        return resp;
    }

    setCabangList(data) {
        this.cabangList = data;
    }

    setLoadingCabang(status) {
        this.isLoadingCabang = status;
    }

    setErrorCabang(status) {
        this.isErrorCabang = status;
    }

    setErrorCauseCabang(err) {
        this.errorCauseCabang = err;
    }

    setErrorcodeCabang(code) {
        this.errorCodeCabang = code;
    }
}


import { LoginRequest, LogoutRequest } from "../request/AuthRequest.js";
import { setAuthentication } from "../external/functions/Cookies.js";

export class AuthControllers {
    loginDetail = {};
    isError = false;
    errorCause = "";
    isLoading = false;

    constructor() { }

    goLogin(username, password) {
        this.setLoading(true);
        const resp = LoginRequest(username, password)
            .then((response) => {
                console.log(response.data)
                this.setError(false);
                this.setLoginDetail(response.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    goLogout(userId) {
        this.setLoading(true);
        const resp = LogoutRequest(userId)
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoginDetail(user) {
        this.loginDetail = user;
        setAuthentication(
            user.token.token, 
            user.user.id, 
            user.user.nama,
            user.user.email,
            user.user.role
        );
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }
}


import { OpnameListRequest} from "../request/OpnameRequest.js";

export class OpnameControllers  {
    opnameList = new Array();
    opnameDetail = new Object();
    isError= false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    loadingProduct = false;
    productList = new Array();
    errorProduct = false;
    errorCauseProduct = "";
    errorCodeProduct = "";


    constructor() {}

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    setOpnameList(data) {
        this.opnameList = data;
    }

    getOpnameList() {
        this.setLoading(true);
        const resp = OpnameListRequest()
        .then((response) => {
            this.setError(false);
            this.setErrorcode(200);
            this.setOpnameList(response.data.data);
            return true;
        }).catch(err => {
            console.log(err)
            this.setError(true);
            this.setErrorCause(err.statusText);
            this.setErrorcode(err.status);
            return false;
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

//     getOpnameDetail(id) {
//         this.setLoading(true);
//         const resp = OpnameDetailRequest(id)
//         .then((response) => {
//             this.setError(false);
//             this.setErrorcode(response.data.code);
//             this.setOpnameDetail(response.data.data);
//         }).catch(err => {
//             this.setError(true);
//             this.setErrorCause(err.response.data.message);
//             this.setErrorcode(err.response.data.code);
//         }).finally(() => {
//             this.setLoading(false);
//         });
       
//         return resp;
//     }

// //     setOpnameDetail(data) {
// //         this.opnameDetail = data;
// //     }

//     createOpnameList(
//         brand,
//         dealer,
//         cabang,
//         alamat,
//         waktu,
//         kode,
//         kodeInt,
//         foto
//     ) {
//         this.setLoading(true);
//         const resp = CreateOpnameRequest(
//             brand,
//             dealer,
//             cabang,
//             alamat,
//             waktu,
//             kode,
//             kodeInt,
//             foto
//         )
//         .then(() => {
//             this.setError(false);
//         }).catch(err => {
//             let text = 'An error occurred while creating dealer cabang'
//                 if(err.response && err.response.data && err.response.data.error){
//                     text = Object.values(err.response.data.error)[0]
//                 }
//             this.setError(true);
//             this.setErrorCause(text);
//         }).finally(() => {
//             this.setLoading(false);
//         });
       
//         return resp;
//     }

//     deleteOpname(id) {
//         this.setLoading(true);
//         const resp = DeleteOpnameRequest(id)
//             .then((response) => {
//                 this.setErrorDelete(false);
//                 this.setMsgDelete(response.data.message);
//             }).catch(err => {
//                 let text = 'An error occurred while deleting dealer cabang'
//                 if(err.response && err.response.data && err.response.data.error){
//                     text = Object.values(err.response.data.error)[0]
//                 }
//                 this.setError(true);
//                 this.setErrorCause(text);
//             }).finally(() => {
//                 this.setLoading(false);
//             });

//         return resp;
//     }

//     setMsgDelete(value) {
//         this.messageDelete = value;
//     }

//     setErrorDelete(status) {
//         this.isErrorDelete = status;
//     }

//     setErrorCauseDelete(err) {
//         this.errorCauseDelete = err;
//     }

//     updateOpnameList(
//         id,
//         brand,
//         dealer,
//         cabang,
//         alamat,
//         waktu,
//         kode,
//         kodeInt,
//         foto
//     ) {
//         this.setLoading(true);
//         const resp = UpdateOpnameRequest(
//             id,
//             brand,
//             dealer,
//             cabang,
//             alamat,
//             waktu,
//             kode,
//             kodeInt,
//             foto
//         )
//         .then(() => {
//             this.setError(false);
//         }).catch(err => {
//             this.setError(true);
//             this.setErrorCause(err.response.data.message);
//         }).finally(() => {
//             this.setLoading(false);
//         });
       
//         return resp;
//     }

    // getProductList() {
    //     this.setLoadingProduct(true);
    //     const resp = ProductListRequest()
    //     .then((response) => {
    //         this.setProductList(response.data.data);
    //         this.setErrorProduct(false);
    //         this.setErrorCodeProduct(response.data.code);
    //     }).catch(err => {
    //         this.setErrorProduct(true);
    //         this.setErrorCauseProduct(err.response.data.message);
    //         this.setErrorCodeProduct(err.response.data.code);
    //     }).finally(() => {
    //         this.setLoadingProduct(false);
    //     });
       
    //     return resp;
    // }

    // setLoadingProduct(status) {
    //     this.loadingProduct = status;
    // }

    // setProductList(data) {
    //     this.productList = data;
    // }
    
    // setErrorProduct(status) {
    //     this.errorProduct = status;
    // }

    // setErrorCauseProduct(err) {
    //     this.errorCauseProduct = err;
    // }

    // setErrorCodeProduct(code) {
    //     this.errorCodeProduct = code;
    // }
}


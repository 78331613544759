import {
    CarListRequest,
    CreateCarRequest,
    DeleteCarRequest,
    UpdateCarRequest,
    // CarDetailRequest
} from "../request/CarRequest.js";

export class CarControllers {
    carList = new Array();
    carDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    mesCargeDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getCarList() {
        this.setLoading(true);
        const resp = CarListRequest()
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.status);
                this.setCarList(response.data);
                return true;
            }).catch(err => {
                console.log(err)
                this.setError(true);
                this.setErrorCause(err.statusText);
                this.setErrorcode(err.status);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    // getCarDetailEdit(id) {
    //     this.setLoading(true);
    //     const resp = CarDetailRequest(id)
    //         .then((response) => {
    //             this.setError(false);
    //             this.setErrorcode(response.data.code);
    //             this.setCarDetail(response.data.data);
    //             return true;
    //         }).catch(err => {
    //             this.setError(true);
    //             this.setErrorCause(err.response.data.mesCarge);
    //             this.setErrorcode(err.response.data.code);
    //             return false;
    //         }).finally(() => {
    //             this.setLoading(false);
    //         });

    //     return resp;
    // }

    // downloadCarList(role, page, limit, keyword, dsoId) {
    //     this.setLoadingDownload(true);
    //     const resp = FilterCarAMRequest(role, page, limit, keyword, dsoId)
    //         .then((response) => {
    //             this.setErrorDownload(false);
    //             this.setErrorcodeDownload(response.data.code);
    //             this.setCarListDownload(response.data.data);
    //         }).catch(err => {
    //             this.setCarListDownload([])
    //             this.setErrorDownload(true);
    //             this.setErrorCauseDownload(err.response.data.mesCarge);
    //             this.setErrorcodeDownload(err.response.data.code);
    //         }).finally(() => {
    //             this.setLoadingDownload(false);
    //         });

    //     return resp;
    // }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setCarListDownload(data) {
        this.carDownload = data;
    }

    setCarDetail(data) {
        this.carDetail = data;
    }

    setCarList(data) {
        this.carList = data;
    }

    createCarList(
        brand,
        model,
        kode
    ) {
        this.setLoading(true);
        const resp = CreateCarRequest(
                brand,
                model,
                kode
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                let text = 'An error occurred while creating car type'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
            this.setError(true);
            this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    getCarDetail(id) {
        this.carDetail = this.carList.find(item => {
            return item.id === id
        });
    }

    deleteCar(id) {
        this.setLoading(true);
        const resp = DeleteCarRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.mesCarge);
            }).catch(err => {
                let text = 'An error occurred while deleting car type'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
            this.setError(true);
            this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.mesCargeDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateCarList(
        id,
        brand,
        model,
        kode
    ) {
        this.setLoading(true);
        const resp = UpdateCarRequest(
                id,
                brand,
                model,
                kode
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                let text = 'An error occurred while updating car type'
                if(err.response && err.response.data && err.response.data.error){
                    text = Object.values(err.response.data.error)[0]
                }
            this.setError(true);
            this.setErrorCause(text);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}
import axios from "axios";
import qs from 'qs'
import { Login, Logout } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`,
    'Content-Type': 'application/x-www-form-urlencoded'
};

export const LoginRequest = (username, password) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${Login()}`,
        data: qs.stringify({
            username: username,
            password: password
        }),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then((response) => {
        return response;
    });

    return resp;
}

export const LogoutRequest = (
    userId
) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${Logout()}`,
        data: {
            userId: userId
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}